import { AppContainer } from "react-hot-loader";
// import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "antd/dist/antd.min.css";
import "dragula/dist/dragula.min.css";
import App from "./components/App";
import "core-js/stable";
import "regenerator-runtime/runtime";

/* global document, Office, module, require */

// initializeIcons();

let isOfficeInitialized = false;

const title = "Contoso Task Pane Add-in";

const render = (Component) => {
  // 检测 IE11 浏览器
  // eslint-disable-next-line no-undef
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  // 如果是 IE11 浏览器，则渲染提示信息
  if (isIE11) {
    const message = "您正在使用的浏览器版本过低，请升级至最新版 Chrome 浏览器以获得更好的浏览体验。";
    const warningElement = document.createElement("div");
    warningElement.innerText = message;
    warningElement.style.backgroundColor = "red";
    warningElement.style.color = "white";
    warningElement.style.padding = "10px";
    warningElement.style.textAlign = "center";
    warningElement.style.fontSize = "18px";

    document.body.innerHTML = "";
    document.body.appendChild(warningElement);
  } else {
    ReactDOM.render(
      <AppContainer>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </AppContainer>,
      document.getElementById("container")
    );
  }
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
