import FlexStyled from "../../styled/FlexStyled";
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import useForceUpdate from "../../hooks/useForceUpdate";
import styled from "styled-components";
import {
  getAiPPTByKeywordByTemplatePercentage,
  getCatalog,
  getIntelligentTemplateTheme,
  getPageListByCatalog,
  getPagesByPptxId,
} from "../../service/services";
import { v4 } from "uuid";
import { message } from "antd";
import replacePpt from "../../common/replacePpt";
import { decodePage } from "../../common";
import withComponent from "../../hoc/withComponent";
import SyllabusViewContainer from "./component/SyllabusViewContainer";
import SyllabusEditContainerList from "./component/SyllabusEditContainerList";

const BackStyled = styled.div`
  font-size: 6px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
`;
const BackBoxStyled = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Scroll$1Styled = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
const itemHandler = (item) => {
  const arr = [];
  item.forEach((item, index) => {
    const preTarget = arr[arr.length - 1];
    if (preTarget?.type === "过渡页" && (item.type === "列表页面" || item.type === "图文页面")) {
      if (!preTarget.children) {
        preTarget.children = [];
      }
      preTarget.children.push({ ...item });
    } else {
      arr.push({ ...item });
    }
  });
  return arr;
};
const leftIcon = (
  <div
    style={{ position: "relative", width: 12, height: 12 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width="12px" height="12px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position:absolute;top: 0;left:0">
    <g id="插件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.402389172">
        <g id="全部生成-选择大纲" transform="translate(-485.000000, -268.000000)" fill="#2C2C2C" fill-rule="nonzero">
            <g id="编组-19" transform="translate(465.000000, 250.000000)">
                <g id="字体下拉备份-6" transform="translate(20.000000, 18.000000)">
                    <path d="M11.0607516,2.38289302 L5.75246688,8.41362446 C5.46012803,8.70629176 5.43355177,9.17107961 5.67640855,9.49852574 L11.0559386,15.6122255 C11.2195152,15.7764563 11.4316185,15.8625 11.6519676,15.8625 C11.8726252,15.8625 12.0847569,15.7762368 12.2429863,15.616596 L12.3238393,15.5227464 C12.5394461,15.2315762 12.5420902,14.8320065 12.3316294,14.5379852 L7.5195,9 L12.2479924,3.56100387 C12.5691059,3.23959497 12.5691059,2.70939507 12.2427171,2.38289302 C11.9162101,2.05570233 11.3872586,2.05570233 11.0607516,2.38289302 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
    }}
  />
);
const Step = withComponent((props) => {
  const { data, onChangeCurrent, onClickBack } = props;
  return (
    <FlexStyled justifyContent="space-between">
      {data.map((syllabus, index) => {
        return <SyllabusViewContainer key={syllabus.id} syllabus={syllabus} onClick={() => onChangeCurrent(index)} />;
      })}
    </FlexStyled>
  );
});

const a = (props) => {
  const { title, visible, onCancel, onSuccess: onSuccess$1, templateId: templateId$1 } = props;
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("");

  const that = useRef({
    exportPptProcessTimer: undefined,
  }).current;
  const onSuccess = async () => {
    setLoading(true);
    setProgress(0);
    setProgressText("PPT生成中请稍后…");

    try {
      const data = ref.current.getData();
      if (data) {
        const requestId = v4();
        clearInterval(that.exportPptProcessTimer);
        that.exportPptProcessTimer = setInterval(async () => {
          const data = await getAiPPTByKeywordByTemplatePercentage({ requestId: requestId });
          if (data) {
            const { percentage, value } = data;
            setProgress(percentage);
            setProgressText(value);
          }
        }, 1000);
        let templateId1 = "688";
        const newData = JSON.parse(JSON.stringify(data));
        newData.forEach((item, index) => {
          item.pageIndex = index.toString();
          if (item.templateId) {
            templateId1 = item.templateId;
          }
          delete item.id;
          delete item.templateId;
          delete item.style;
        });
        const { pptId, replaceTemplate, templateId } = await getPageListByCatalog(newData, {
          pptTitle: title,
          templateId: templateId$1 ? templateId$1 : templateId1,
          requestId,
        });
        const currentPagesData = await getPagesByPptxId({ pptId, limit: 199, page: 1 });
        const currentPages = {};
        currentPagesData.dataList
          ?.map((page) => decodePage(page))
          .forEach((page) => {
            currentPages[page.id] = page;
          });
        const targetTheme = JSON.parse(await getIntelligentTemplateTheme(undefined, { templateId: templateId }));
        const pages = replacePpt({ currentTheme: {}, currentPages, targetTheme, replaceTemplate });
        setProgress(100);
        let p = onSuccess$1?.(pptId, pages);
        localStorage.setItem("default-search-value", title);
        if (p?.then) {
          await p;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setProgress(0);
      setProgressText("");
      clearInterval(that.exportPptProcessTimer);
    }
  };
  const onClickBack = () => {
    ref.current.back();
  };
  useEffect(() => {
    return () => {
      clearInterval(that.exportPptProcessTimer);
    };
  }, []);

  return (
    <>
      <Content$1 {...props} ref={ref} title={title} />
    </>
  );
};
export default React.forwardRef((props, ref) => {
  const { title = "工作", data, onClickBack, onClickTemplate } = props;
  const boxRef = useRef();
  const [loadingText, setLoadingText] = useState("大纲正在生成");
  const that = useRef({
    syllabusEditIndex: 0,
    data: data,
    loading: false,
  }).current;
  const setLoading = (status, text) => {
    that.loading = status;
    if (text) {
      setLoadingText(text);
    }
  };
  const [, update] = useForceUpdate();
  const onChangeCurrent = (index) => {
    that.syllabusEditIndex = 0;
    that.target = JSON.parse(JSON.stringify(that.data[index]));
    update();
  };

  const addItemById = (siblingId, type = "列表页面", flag) => {
    let index = that.target.findIndex((item) => item.id === siblingId);
    if (flag) {
      index = index + 1;
    }
    const preItem = that.target[0];
    const newItem = { id: v4(), title: "", type: type, style: preItem?.style, templateId: preItem?.templateId };
    if (index !== -1) {
      that.target.splice(index, 0, newItem);
      that.target = [...that.target];
    } else {
      that.target.push(newItem);
      that.target = [...that.target];
    }
    that.addItemFocusId = newItem.id;
    update();
  };
  const deleteItemById = (id) => {
    const index = that.target.findIndex((item) => item.id === id);
    if (index !== -1) {
      that.target.splice(index, 1);
      that.target = [...that.target];
    }
    if (that.target[index]) {
      that.addItemFocusId = that.target[index].id;
    }
    update();
  };
  const changeItemById = (id, value) => {
    const index = that.target.findIndex((item) => item.id === id);
    if (index !== -1) {
      that.target[index].title = value;
      that.target = [...that.target];
      update();
    }
  };
  const moveItemById = (id, siblingId) => {
    const index = that.target.findIndex((item) => item.id === id);
    if (index !== -1) {
      const item = that.target[index];
      that.target.splice(index, 1);
      const siblingIndex = that.target.findIndex((item) => item.id === siblingId);
      if (siblingIndex !== -1) {
        that.target.splice(siblingIndex, 0, item);
      } else {
        that.target.push(item);
      }
    }
    that.target = [...that.target];
    update();
  };
  const getScrollContainer = useCallback(() => boxRef.current, []);
  const back = useCallback(() => {
    that.target = null;
    update();
  }, []);
  useImperativeHandle(ref, () => ({ getData: () => that.target, setLoading, back }));
  useEffect(() => {
    if (that.addItemFocusId) {
      const target = boxRef.current.querySelector(`[id='input-${that.addItemFocusId}']`);
      if (target) {
        target.focus?.();
      }
      that.addItemFocusId = undefined;
    }
  }, [that.addItemFocusId]);
  useEffect(() => {
    that.rect = boxRef.current?.getBoundingClientRect();
    update();
  }, []);
  const leftArr = useMemo(() => {
    return that.data.map((item, index) => {
      return itemHandler(item);
    });
  }, [that.data]);
  const targetData = useMemo(() => (that.target ? itemHandler(that.target) : undefined), [that.target]);
  return (
    <Scroll$1Styled ref={boxRef}>
      <div style={{ margin: 40 }}>
        <FlexStyled>
          <BackBoxStyled onClick={onClickBack} alignItems="center" style={{ marginBottom: 20 }}>
            {leftIcon}
            <BackStyled style={{ marginLeft: 6 }}>返回</BackStyled>
          </BackBoxStyled>
        </FlexStyled>
        {that.target ? (
          <SyllabusEditContainerList
            data={targetData}
            target={that.target}
            changeItemById={changeItemById}
            deleteItemById={deleteItemById}
            addItemById={addItemById}
            getScrollContainer={getScrollContainer}
            rect={that.rect}
            onClickTemplate={onClickTemplate}
            moveItemById={moveItemById}
          />
        ) : (
          <Step data={leftArr} onClickBack={onClickBack} onChangeCurrent={onChangeCurrent} />
        )}
        {that.target && (
          <FlexStyled alignItems="center" justifyContent="center" style={{ marginTop: 20 }}>
            <FlexStyled
              onClick={() => onClickTemplate(that.target)}
              alignItems="center"
              justifyContent="center"
              style={{
                width: 256,
                height: 43,
                background: "#019885",
                borderRadius: 4,
                fontSize: 14,
                lineHeight: "14px",
                fontWeight: 400,
                color: "#FFFFFF",
              }}
            >
              选模板
            </FlexStyled>
          </FlexStyled>
        )}
      </div>
    </Scroll$1Styled>
  );
});
