import withComponent from '../hoc/withComponent';
import { createPortal } from 'react-dom';
import FlexStyled from '../styled/FlexStyled';
import Pointer from '../styled/Pointer';
import React from 'react';

export default withComponent((props) => {
  let { visible, progress = 0, desText = '', name = '', zIndex, onCancel } = props;
  if (progress > 100) {
    progress = 100;
  } else if (progress < 0) {
    progress = 0;
  } else if (!progress) {
    progress = 0;
  }
  const progressText = Math.floor(progress);
  return visible && createPortal(<FlexStyled alignItems='center' justifyContent='center' style={{
    position: 'fixed',
    inset: 0,
    background: 'rgba(0,0,0,0.5)',
    backdropFilter: 'blur(2px)',
    zIndex,
  }}>
    <div style={{
      width: 480,
      height: 118,
      background: '#FFFFFF',
      borderRadius: 6,
      padding: '0 24px',
    }}>
      <FlexStyled alignItems='center' justifyContent='space-between' style={{ marginTop: 20 }}>
        <div style={{ fontSize: 14, fontWeight: 'bold', color: '#464646', minHeight: 22, whiteSpace: 'nowrap' }}>{name}</div>
        {!!onCancel && <Pointer onClick={onCancel} style={{ fontSize: 12, fontWeight: 400, color: '#01A594' }}>取消</Pointer>}
      </FlexStyled>
      <div style={{
        position: 'relative',
        background: '#F2F3F5',
        height: 4,
        borderRadius: 2,
        marginTop: 24,
      }}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: (progress / 100) * 432,
          transition: 'width 0.5s',
          background: 'linear-gradient(90deg, #02C9BB 0%, #019885 100%)',
        }} />
        <FlexStyled alignItems='center' justifyContent='center' style={{
          position: 'absolute',
          left: -18,
          top: -22,
          fontSize: 12,
          transition: 'transform 0.5s',
          transform: `translateX(${(progress / 100) * 432}px)`,
          color: 'white', backgroundSize: '100% 100%', backgroundImage: 'url(https://oss.jianzeppt.com/user/4548/0df3b44e-4660-44e8-afcb-99c302c89ede.png)', width: 35, height: 19,
        }}>
          <div style={{ position: 'relative', top: -2 }}>{progressText}%</div>
        </FlexStyled>
      </div>
      <FlexStyled justifyContent='center' style={{ fontSize: 12, fontWeight: 400, color: '#999999', lineHeight: '12px', marginTop: 18 }}>
        {desText}
      </FlexStyled>

    </div>
  </FlexStyled>, document.body);
});
