import { useCallback, useEffect, useRef } from 'react';

export default (props) => {
  const { getScrollContainer, isPre, isNext, speed = 10 } = props;
  const that = useRef({
    onDragStartTimer: 0,
    drag: false,
  }).current;
  const performDragScroll = useCallback((pageY) => {
    if (!that.drag) {
      return;
    }
    const scrollContainer = getScrollContainer();
    if (scrollContainer) {
      if (!!isPre(pageY) && (that.prePageY >= pageY)) {
        scrollContainer.scrollBy(0, -speed);
      } else if (!!isNext(pageY) && (that.prePageY <= pageY)) {
        scrollContainer.scrollBy(0, speed);
      }
    }
  }, []);
  const onMouseMove = useCallback((e) => {
    performDragScroll(e.pageY);
    that.prePageY = e.pageY;
  }, []);
  const onDragStart = useCallback(() => {
    that.drag = true;
    that.onDragStartTimer = setInterval(() => {
      performDragScroll(that.prePageY);
    }, 20);
    document.addEventListener('mousemove', onMouseMove);
  }, []);
  const onDragOver = useCallback(() => {
    clearInterval(that.onDragStartTimer);
    that.drag = false;
    document.removeEventListener('mousemove', onMouseMove);
  }, []);
  useEffect(() => {
    return () => {
      onDragOver();
    };
  }, []);
  return { onDragStart, onDragOver };
}
