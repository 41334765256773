import axios from "axios";

export let searchList = [];

export let searchListPromise = axios.get('https://oss.jianzeppt.com/public/static/search_words.json')
  .then((res) => {
    if (res.data) {
      searchList = res.data.map((text, index) => {
        return {
          label: text,
          value: 'auto-complete-' + index,
        };
      });
    }
  })
  .catch(() => void 0);
