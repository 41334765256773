import axios from "axios";
import store from "store";

const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  CLIENT_ERROR: 400,
  AUTHENTICATE: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

/*store.set("user",
  {"id":4556,"mobile":"","accessToken":"eq1JlFKAzCud1OaZJMvF3vuLckMDsDyhm2OEoduTsSGAMUTlaDQ9wSy7CQoKnRCuZgXQIIvFjcYiwZ7K320kSA==","jwtToken":null,"userName":"用户4556","icon":"https://oss.jianzeppt.com/headerIcon/icon_36_4.svg","mail":"","roleId":-1,"isFirstLogin":false,"downloadBeans":null,"realName":"","industry":null,"company":"","invitationName":"","invitation":"-1","vipTime":1681990681000,"editableVipTime":1681990681000,"isAgree":null,"isWXLogin":true,"is_half_price":1,"isAb":0,"canUpgrade":null,"vipType":20,"isOldAutoUpgrade":false,"admin":true,"token":"eq1JlFKAzCud1OaZJMvF3vuLckMDsDyhm2OEoduTsSGAMUTlaDQ9wSy7CQoKnRCuZgXQIIvFjcYiwZ7K320kSA=="}
)*/
function request(url, data = {}, method = "GET") {
  const user = store.get("user") || {};
  // console.log("user",user);
  // if(window.location.href.indexOf('/pptx/login') !== -1){
  return new Promise(function (resolve, reject) {
    let axiosJson = {
      url: url,
      method: method,
      headers: {
        "User-Id": user.id || 0,
        "User-Token": user.token || user.accessToken || "QQ",
        "Content-Type": "application/json; charset=UTF-8",
      },
    };
    if (method === "GET") {
      axiosJson["params"] = data;
    } else {
      axiosJson["data"] = data;
    }
    axios(axiosJson)
      .then((res) => {
        //升级
        try {
        } catch (e) {}
        if (res.status === HTTP_STATUS.NOT_FOUND) {
          reject("请求资源不存在");
        } else if (res.status === HTTP_STATUS.BAD_GATEWAY) {
          reject("服务端出现了问题");
        } else if (res.status === HTTP_STATUS.FORBIDDEN) {
          reject("没有权限访问");
        } else if (res.status === HTTP_STATUS.AUTHENTICATE) {
          // pageToLogin()
          reject("需要鉴权");
        } else if (res.status === HTTP_STATUS.SUCCESS) {
          if (res.data.code === 0) {
            resolve(res.data.data);
          } else if (res.data.code === 1009) {
            if (user) {
              store.remove("user");
            } else {
            }
            resolve(res.data.data);
          } else {
            reject(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  //   }
  // }
}

request.get = (url, data) => {
  return request(url, data, "GET");
};

request.post = (url, data) => {
  return request(url, data, "POST");
};

export default request;
