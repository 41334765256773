import { getCatalog } from "../service/services";
import { v4 } from "uuid";
import { useRef } from "react";
import useForceUpdate from "./useForceUpdate";
import FlexStyled from "../styled/FlexStyled";
import React  from "react";

const Loading = (props) => {
  const { text } = props;
  return <FlexStyled alignItems="center" justifyContent="center" style={{ height: "100%", width: "100%" }}>
    <FlexStyled flexDirection="column" alignItems="center">
      <img width={130} height={44} src="https://oss.jianzeppt.com/user/4556/dd606090-e1a4-4579-9034-1de2e9f80e3d.gif"
           style={{ marginBottom: 13 }} />
      <div style={{ marginTop: 13, fontSize: 14, color: "rgba(44, 44, 44, 1)", lineHeight: "14px" }}>{text}</div>
      <div
        style={{ marginTop: 5, fontSize: 12, color: "rgba(153, 153, 153, 1)", lineHeight: "12px" }}>基于内容复杂度，需要10秒至3分钟不等
      </div>
    </FlexStyled>
  </FlexStyled>;
};
export default () => {
  const that = useRef({
    loading: false,
    loadingText: false
  }).current;
  const [, update] = useForceUpdate();
  const fetchData = async (title) => {
    try {
      that.loading = true;
      that.loadingText = "大纲正在生成";
      update();
      let data = await getCatalog({}, { pptTitle: title, num: 3 });
      return data.map((item, index) => {
        return item.repoPageList?.map((item2) => {
          item2.id = v4();
          item2.style = item.style;
          item2.templateId = item.templateId;
          return item2;
        }).filter((item) => item.type !== "封面页面");
      });
    } catch (e) {

    } finally {
      that.loading = false;
      that.loadingText = "大纲正在生成";
      update();
    }
  };
  return {
    loading: that.loading,
    loadingText: that.loadingText,
    fetchData,
    context: <>
      {that.loading && <Loading text={that.loadingText} />}
    </>
  };

}
