import qs from 'qs';

export const strictStringify = (obj, addQueryPrefix = true, indices) => {
  return qs.stringify(obj, {
    strictNullHandling: true,
    addQueryPrefix: addQueryPrefix,
    indices,
  });
};
export const encodePage = (page) => {
  const json = { ...page };
  delete json.id;
  return {
    uuid: page.id,
    pptId: page.pptId,
    type: page.type,
    body: JSON.stringify(json),
  };
};

export const decodePage = (data) => {
  return {
    ...JSON.parse(data.body),
    id: data.uuid,
    pptId: data.pptId,
    type: data.type,
    originId:data.id

  };
};
