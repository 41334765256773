import withComponent from "../../hoc/withComponent";
import Syllabus from "../Syllabus";
import * as React from "react";
import { useRef, useState } from "react";
import useForceUpdate from "../../hooks/useForceUpdate";
import SyllabusModal from "../SyllabusModal";
import SelectTemplate from "../SelectTemplate";
import { v4 } from "uuid";
import {
  deleteThenSaveBatch,
  getAiPPTByKeywordByTemplatePercentage,
  getIntelligentTemplateTheme,
  getPageListByCatalog,
  getPagesByPptxId,
  getPptNotDom,
} from "../../service/services";
import { decodePage, encodePage } from "../../common";
import replacePpt from "../../common/replacePpt";
import getPageListByPages from "../../common/getPageListByPages";
import LoadingModal from "../LoadingModal";
import store from "store";
import axios from "axios";
import FlexStyled from "../../styled/FlexStyled";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, message } from "antd";
import "./index.less";

export default withComponent(() => {
  const that = useRef({
    step: 0,
    title: "",
    data: [],
    target: [],
    pageList: [],
  }).current;
  const [, update] = useForceUpdate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("");
  const onSearch = ({ title, data }) => {
    that.step = 1;
    that.title = title;
    that.data = data;
    update();
  };
  const onClickBack = () => {
    that.step = 0;
    that.data = [];
    update();
  };
  const onClickTemplate = (target) => {
    that.target = target;
    that.step = 2;
    update();
  };
  const back = () => {
    that.target = undefined;
    that.step = 1;
    update();
  };
  const onClickApply = async (templateId1) => {
    try {
      const requestId = v4();
      setLoading(true);
      setProgress(0);
      setProgressText("PPT生成中请稍后…");
      clearInterval(that.exportPptProcessTimer);
      that.exportPptProcessTimer = setInterval(async () => {
        const data = await getAiPPTByKeywordByTemplatePercentage({ requestId: requestId });
        if (data) {
          const { percentage, value } = data;
          if (percentage < 98) {
            setProgress(percentage);
          }
          setProgressText(value);
        }
      }, 1000);
      const newData = JSON.parse(JSON.stringify(that.target));
      newData.forEach((item, index) => {
        item.pageIndex = index.toString();
        delete item.id;
        delete item.templateId;
        delete item.style;
      });
      const { pptId, replaceTemplate, templateId } = await getPageListByCatalog(newData, {
        pptTitle: that.title,
        templateId: templateId1,
        requestId,
      });
      setProgress(98);
      clearInterval(that.exportPptProcessTimer);
      const currentPagesData = await getPagesByPptxId({ pptId, limit: 199, page: 1 });
      const currentPages = {};
      currentPagesData.dataList
        ?.map((page) => decodePage(page))
        .forEach((page) => {
          currentPages[page.id] = page;
        });
      const targetTheme = JSON.parse(await getIntelligentTemplateTheme(undefined, { templateId: templateId }));
      const pages = replacePpt({ currentTheme: {}, currentPages, targetTheme, replaceTemplate });
      const pageList = Object.keys(pages).map((id) => encodePage(pages[id]));
      await deleteThenSaveBatch(pageList, { pptId });
      const user = store.get("user") || {};

      const { pptPath } = await getPptNotDom(
        {
          exportType: "pptx",
          fileName: that.title,
        },
        { pptId, userId: user.id }
      );
      const data = await axios.get(pptPath, { responseType: "blob" });
      const reader = new FileReader();
      reader.onload = (event) => {
        const startIndex = reader.result.toString().indexOf("base64,");
        const copyBase64 = reader.result.toString().substr(startIndex + 7);
        PowerPoint.createPresentation(copyBase64);
      };
      reader.readAsDataURL(data.data);
      setProgress(100);
      update();
    } catch (e) {
      if (e?.msg) {
        message.error(e.msg);
      }
      console.log(e);
    } finally {
      setLoading(false);
      clearInterval(that.exportPptProcessTimer);
    }
  };
  let c;
  if (that.step === 0) {
    c = (
      <div style={{ height: "100vh", width: "100vw" }}>
        <Syllabus onSearch={onSearch} />
      </div>
    );
  } else if (that.step === 1) {
    c = (
      <SyllabusModal data={that.data} title={that.title} onClickTemplate={onClickTemplate} onClickBack={onClickBack} />
    );
  } else if (that.step === 2) {
    c = (
      <div style={{ height: "100vh", width: "100vw" }}>
        <SelectTemplate back={back} onClickApply={onClickApply} />
      </div>
    );
  }
  // console.log(that.step);

  const handleDelete = () => {
    store.remove("user");
    window.location.reload();
  };

  const items = [
    {
      key: "1",
      label: <div onClick={handleDelete}>注销</div>,
    },
  ];
  return (
    <>
      <div className="avator">
        <Dropdown menu={{ items }} placement="bottom">
          <Avatar style={{ backgroundColor: "rgb(17, 187, 166)" }} size={36} icon={<UserOutlined />} />
        </Dropdown>
      </div>

      {c}
      <LoadingModal zIndex={9999} visible={loading} desText={progressText} progress={progress} name={"PPT生成中"} />
    </>
  );
});
