import * as React from "react";
import qs from "qs";
import store from "store";
import SyllabusComponent from "../../component/SyllabusComponent";
import FlexStyled from "../../styled/FlexStyled";
import LoginModal, { LoginContent } from "../../component/Login/WxLoginModal";
import Login from "../../component/Login/Login";
import { login_anonymous } from "../../service/services";
import isLogin from "../../common/isLogin";

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      step: 0,
    };
  }

  click = async () => {
    Office.context.ui.displayDialogAsync("https://localhost:3000/taskpane.html?type=login");
  };
  onSuccess = async () => {
    this.forceUpdate();
  };

  componentDidMount() {
    // this.click();
    const login_anonymousFn = async () => {
      // 删除原来的用户，重新注册一个匿名用户
      const user = await login_anonymous({});
      if (!store.get("user")) {
        store.set("user", user);
      }
    };
    login_anonymousFn();
  }

  render() {
    // const user = store.get("user");
    // if (user && isLogin()) {
    //   return <SyllabusComponent />;
    // }
    return (
      <FlexStyled justifyContent="center" alignItems="center">
        {/* <Login visible onSuccess={this.onSuccess} /> */}
        <iframe style={{ width: "100vw", height: "100vh", border: "none" }} src={"/mpptx/"} />
      </FlexStyled>
    );
  }
}
