import React from 'react';
import ErrorBoundary from '../component/ErrorBoundary';

export default (WrappedComponent) => {
  return React.forwardRef((props, ref) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} ref={ref} />
      </ErrorBoundary>
    );
  });
};
