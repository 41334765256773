import React, { useCallback, useRef } from 'react';
import useDragHandler from '../useDragHandler2';
import FlexStyled from '../../../styled/FlexStyled';
import SyllabusEditContainer from './SyllabusEditContainer';
import withComponent from '../../../hoc/withComponent';
import styled from 'styled-components';


const CircleBoxStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  left: -3px;
  background: white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CircleStyled = styled.div`
  width: 8px;
  height: 8px;
  background: #D8D8D8;
  border-radius: 50%;
  overflow: hidden;
`;
const CircleHollowStyled = styled.div`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  overflow: hidden;
`;
const AcrossLine = styled.div`
  width: 58px;
  height: 1px;
  border: 1px solid #EEEEEE;
  flex-shrink: 0;
`;
const VerticalLine = styled.div`
  position: absolute;
  top: 28px;
  bottom: 35px;
  left: 3px;
  width: 1px;
  background: #EEEEEE;
`;


export default withComponent((props) => {
  const { target, data, getScrollContainer, rect, moveItemById, changeItemById, addItemById, deleteItemById } = props;
  const ref = useRef();
  const getListContainer = useCallback(() => ref.current, [ref.current]);
  const onDrop = useCallback((el, container, source, sibling) => {
    const id = el?.getAttribute('id');
    const siblingId = sibling?.getAttribute('id') || null;
    moveItemById(id, siblingId);
  }, []);
  useDragHandler({
    getScrollContainer,
    getListContainer,
    speed: 4,
    isPre: (pageY) => (pageY < (rect.y + 50)),
    isNext: (pageY) => (pageY > (rect.y + rect.height - 50)),
    onDrop,
  });
  return <FlexStyled justifyContent='center' style={{ padding: '24px 0', position: 'relative' }}>
    <div style={{ position: 'relative' }}>
      <VerticalLine />
      <div ref={ref} style={{ position: 'relative' }}>
        {
          data?.map((item, index) => {
            return <>
              <SyllabusEditContainer key={item.id} onChange={(value) => changeItemById(item.id, value)}
                                     index={index}
                                     id={item.id}
                                     value={item.title}
                                     showAdd={false}
                                     showDelete={target?.length !== 1}
                                     onClickAdd={() => addItemById(item.id, '过渡页')}
                                     onClickAddBottom={() => addItemById(item.id, '过渡页', true)}
                                     showBottomAdd
                // showBottomAdd={index === data.length - 1 && (!item.children || item.children.length === 0)}
                                     onClickDelete={() => deleteItemById(item.id)} />
              {item.children?.map((child, index2) => {
                return <SyllabusEditContainer key={child.id}
                                              id={child.id}
                                              isSecondLevel
                                              showDelete={target?.length !== 1}
                                              onClickAdd={() => addItemById(child.id)}
                                              onClickAddBottom={() => addItemById(child.id, undefined, true)}
                                              onChange={(value) => changeItemById(child.id, value)}
                                              index={index}
                                              index2={index2}
                                              value={child.title}
                                              showAdd={false}
                                              showBottomAdd
                  // showBottomAdd={(index === data.length - 1) && (index2 === item.children.length - 1)}
                                              onClickDelete={() => deleteItemById(child.id)} />;
              })}
            </>;
          })
        }
      </div>
    </div>
  </FlexStyled>;

});



