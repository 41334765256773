import { message, Pagination, Spin } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import FlexStyled from "../styled/FlexStyled";
import styled from "styled-components";
import Pointer from "../styled/Pointer";
import {
  changeTemplate,
  deleteThenSaveBatch,
  getAiPPTByKeywordByTemplatePercentage,
  getEditableId,
  getFilterCriteria,
  getIntelligentTemplateTheme, getPageListByCatalog,
  getPagesByPptxId,
  getPandaList,
  getPptxById
} from "../service/services";
import useForceUpdate from "../hooks/useForceUpdate";
import { v4 } from "uuid";
import { decodePage, encodePage } from "../common";

import replacePpt from "../common/replacePpt";


const searchIcon = <div style={{ height: 18, width: 18 }} dangerouslySetInnerHTML={{
  __html: `<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="新建文档流程～～" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="切换ppt模板弹窗" transform="translate(-1131.000000, -323.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="编组" transform="translate(747.000000, 308.000000)">
                <g id="搜索" transform="translate(384.000000, 15.000000)">
                    <path d="M12.598957,3.7562412 C10.1570308,1.3135322 6.19465995,1.3135322 3.75273375,3.7562412 C1.31080755,6.1989502 1.31080755,10.1625912 3.75273375,12.6053002 C6.19465995,15.0480092 10.1570308,15.0480092 12.598957,12.6053002 C15.0408832,10.1625912 15.0408832,6.1989502 12.598957,3.7562412 Z M2.39815582,13.9603124 C-0.799385275,10.770964 -0.799385275,5.59057739 2.39815582,2.39201127 C5.59569692,-0.797337089 10.7652086,-0.797337089 13.9627497,2.39201127 C17.1602908,5.59057739 17.1602908,10.7617463 13.9627497,13.9603124 C10.7652086,17.1588785 5.58648211,17.1588785 2.39815582,13.9603124 Z M17.6763205,17.6750736 C17.2432242,18.1083088 16.5336833,18.1083088 16.100587,17.6750736 L14.5156387,16.098835 C14.0825423,15.6655998 14.0825423,14.9558315 14.5156387,14.5225963 C14.948735,14.0893612 15.6582758,14.0893612 16.0913722,14.5225963 L17.6671057,16.098835 C18.1094168,16.5320702 18.1094168,17.2418384 17.6763205,17.6750736 L17.6763205,17.6750736 Z" id="形状"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}} />;
const svgIcon = <div style={{ width: 13, height: 13, position: "relative" }} dangerouslySetInnerHTML={{
  __html: `<svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position:absolute;top: 0">
    <g id="新建文档流程～～" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.402389172">
        <g id="点击模板-应用整套模板" transform="translate(-485.000000, -307.000000)" fill="#2C2C2C" fill-rule="nonzero">
            <g id="字体下拉备份-6" transform="translate(485.000000, 307.000000)">
                <path d="M7.98832059,1.7209783 L4.15455941,6.07650656 C3.9434258,6.28787738 3.92423183,6.6235575 4.0996284,6.86004637 L7.98484452,11.2754962 C8.10298318,11.3941073 8.25616895,11.45625 8.41530994,11.45625 C8.57467379,11.45625 8.72787998,11.3939488 8.8421568,11.2786526 L8.90055064,11.2108724 C9.05626663,11.0005828 9.05817623,10.7120047 8.9061768,10.4996559 L5.43075,6.5 L8.84577231,2.57183613 C9.07768757,2.33970748 9.07768757,1.95678533 8.84196234,1.7209783 C8.60615171,1.4846739 8.22413122,1.4846739 7.98832059,1.7209783 Z" id="-"></path>
            </g>
        </g>
    </g>
</svg>`
}} />;
const topIcon = <div style={{ width: 8, height: 8, position: "relative" }} dangerouslySetInnerHTML={{
  __html: `<svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="rgb(153,153,153)"
                                    style="position: absolute;top: 0"
                >
                  <path
                    d="M573.056 272l308.8 404.608A76.8 76.8 0 0 1 820.736 800H203.232a76.8 76.8 0 0 1-61.056-123.392L450.976 272a76.8 76.8 0 0 1 122.08 0z"
                    p-id="3015"
                  ></path>
                </svg>`
}} />;
const bottomIcon = <div style={{ width: 8, height: 8, position: "relative" }} dangerouslySetInnerHTML={{
  __html: `<svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  fill="rgb(153,153,153)"
                  style="position: absolute;top: 0"
                >
                  <path
                    d="M573.056 752l308.8-404.608A76.8 76.8 0 0 0 820.736 224H203.232a76.8 76.8 0 0 0-61.056 123  .392l308.8 404.608a76.8 76.8 0 0 0 122.08 0z"
                    p-id="2102"
                  ></path>
                </svg>`
}} />;


const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`;
const Styled2 = styled.div`
  margin-top: 14px;
  width: 426px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;


  & input {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }

  & input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888888;
  }
`;
const Styled3 = styled.div`
  width: 56px;
  height: 38px;
  background: #019885;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Styled4 = styled.div`
  height: 28px;
  background: ${props => props.active ? "#019885" : "transparent"};
  border-radius: 6px;
  padding: 7px 16px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: ${props => props.active ? "#FFFFFF" : "#666666"};
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
`;
const Styled5 = styled.div`
  padding: 0 26px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Styled6 = styled.img`
  cursor: pointer;
  width: 224px;
  height: 126px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.20);
  margin-bottom: 20px;
`;
const Styled7 = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
const Styled8 = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;
const Styled9 = styled.div`
  height: 100%;
  position: relative;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const BackStyled = styled.div`
  font-size: 6px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
`;
const BackBoxStyled = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Content2 = (props) => {
  const { type, list, name, back, target, oldTemplateId, pageList, pptId: currentPPTId, onClickApply } = props;
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState("");
  const that = useRef({}).current;
  useEffect(() => {
    return () => {
      clearInterval(that.getAiPPTByKeywordByTemplatePercentageTimer);
    };
  }, []);
  return <FlexStyled flexGrow={1} flexDirection="column">
    <Styled8 onClick={back} style={{ height: 40, color: "rgba(153, 153, 153, 1)", fontSize: 13 }}>
      <div style={{ marginLeft: 20, marginRight: 5 }}>{svgIcon}</div>
      <div>返回</div>
    </Styled8>
    <FlexStyled flexGrow={1} style={{ height: 0 }}>
      <Styled7 style={{ width: 696, paddingLeft: 40, paddingRight: 50 }}>
        {
          list.map((item) => {
            return <img key={item} width={295} height={166} src={item + "?x-oss-process=image/resize,h_166,w_295"}
                        style={{ marginBottom: 16, border: "1px solid rgba(218, 218, 218, 1)" }} />;
          })
        }
      </Styled7>
      <FlexStyled flexDirection="column" flexShrink={0} style={{ width: 256 + 38 }}>
        <div style={{ fontSize: 20, color: "#666666", lineHeight: "20px" }}>{name}</div>
        <div style={{ fontSize: 14, fontWeight: 400, color: "#999999", marginTop: 16, lineHeight: "14px" }}>页数</div>
        <Pointer onClick={() => onClickApply(target.id)} style={{ marginTop: 18 }}>
          <FlexStyled alignItems="center" justifyContent="center" style={{
            width: 256,
            height: 43,
            background: "#019885",
            borderRadius: 4,
            fontSize: 14,
            lineHeight: "14px",
            fontWeight: 400,
            color: "#FFFFFF"
          }}>应用整套模板</FlexStyled>
        </Pointer>
      </FlexStyled>
    </FlexStyled>


  </FlexStyled>;
};

const leftIcon = <div style={{ position: "relative", width: 12, height: 12 }} dangerouslySetInnerHTML={{
  __html: `<svg width="12px" height="12px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position:absolute;top: 0;left:0">
    <g id="插件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.402389172">
        <g id="全部生成-选择大纲" transform="translate(-485.000000, -268.000000)" fill="#2C2C2C" fill-rule="nonzero">
            <g id="编组-19" transform="translate(465.000000, 250.000000)">
                <g id="字体下拉备份-6" transform="translate(20.000000, 18.000000)">
                    <path d="M11.0607516,2.38289302 L5.75246688,8.41362446 C5.46012803,8.70629176 5.43355177,9.17107961 5.67640855,9.49852574 L11.0559386,15.6122255 C11.2195152,15.7764563 11.4316185,15.8625 11.6519676,15.8625 C11.8726252,15.8625 12.0847569,15.7762368 12.2429863,15.616596 L12.3238393,15.5227464 C12.5394461,15.2315762 12.5420902,14.8320065 12.3316294,14.5379852 L7.5195,9 L12.2479924,3.56100387 C12.5691059,3.23959497 12.5691059,2.70939507 12.2427171,2.38289302 C11.9162101,2.05570233 11.3872586,2.05570233 11.0607516,2.38289302 Z" id="-"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`
}} />;

const TagContainer = (props) => {
  const { item, onClickTag, tabIndex, index } = props;
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  useEffect(() => {
    const dom = ref.current;
    let width = 0;
    dom.children?.forEach?.((child) => {
      width += child.offsetWidth;
    });
    if (width > ref.current.offsetWidth) {
      setShowMore(true);
    }
    setShow(true);
  }, []);
  return <FlexStyled style={{ opacity: show ? 1 : 0, width: "100%", marginBottom: 10 }}>
    <FlexStyled flexShrink={0} style={{ whiteSpace: "nowrap" }}>{item.name}:</FlexStyled>
    <FlexStyled flexGrow={1} ref={ref} flexWrap="wrap" style={{
      marginLeft: 22,
      height: showMore2 ? undefined : 28,
      overflow: showMore2 ? undefined : "hidden"
    }}>
      {item.children?.map?.((item, index2) => {
        return <Styled4 key={item.id} onClick={() => onClickTag(index, index2)} active={tabIndex[index] === index2}
                        style={{ marginBottom: 10 }}>
          {item.name}
        </Styled4>;
      })}
    </FlexStyled>
    {
      <FlexStyled flexShrink={0} alignItems="center" style={{ display: showMore ? undefined : "none", height: 28 }}>
        {
          showMore2
            ? <Pointer onClick={() => setShowMore2(false)}>
              <FlexStyled alignItems="center">
                <div>收起</div>
                <div>{topIcon}</div>
              </FlexStyled>
            </Pointer>
            : <Pointer onClick={() => setShowMore2(true)}>
              <FlexStyled alignItems="center">
                <div>更多</div>
                <div>{bottomIcon}</div>
              </FlexStyled>
            </Pointer>
        }
      </FlexStyled>
    }
  </FlexStyled>;
};
export default (props) => {
  const { type, onClickApply } = props;
  const that = useRef({
    target: null,
    limit: 20,
    fetchListOver: false
  }).current;
  const [, update] = useForceUpdate();
  const [list, setList] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState("");
  const [visible2, setVisible2] = useState(false);
  const [tabIndex, setTabIndex] = useState([0, 0]);
  const fetchTag = async () => {
    try {
      that.tagLoading = true;
      update();
      const data = await getFilterCriteria().finally(() => {
        that.tagLoading = false;
        update();
      });
      const arr = [];
      const colors = data["color"];
      for (let key in data) {
        if (key === "color") {
          continue;
        }
        if (key === "颜色") {
          const item = {
            id: key,
            name: key,
            children:
              data[key]?.map((item, index) => {
                let color = colors[index];
                if (item === "全部") {
                  color =
                    "url(https://jianzeppt-backend-shanghai.oss-cn-shanghai.aliyuncs.com/organizeData/1646807263519-cgTh%20-%20%E5%89%AF%E6%9C%AC.png)";
                }
                return { id: item, background: color, name: item };
              }) || []
          };
          arr.push(item);
        } else {
          const item = {
            id: key,
            name: key,
            children: data[key]?.map((item) => ({ id: item, name: item })) || []
          };
          arr.push(item);
        }
      }
      setTabs(arr.filter((item) => item.id !== "颜色"));
    } catch (e) {
      message.error("获取标签失败");
    }
  };
  const onClickTag = (index, index2) => {
    setTabIndex((arr) => {
      arr[index] = index2;
      return [...arr];
    });
    setTimeout(() => {
      fetchList();
    }, 100);
  };
  const fetchList = async (value, page = 1) => {
    if (that.loading) return;
    that.loading = true;
    update();
    const sceneIndex = tabs.findIndex((item) => item.id === "场景");
    const styleIndex = tabs.findIndex((item) => item.id === "风格");
    const scene = tabs[sceneIndex]?.children?.[tabIndex[sceneIndex]]?.name;
    const style = tabs[styleIndex]?.children?.[tabIndex[styleIndex]]?.name;
    if (page === 1) {
      setList([]);
    }
    await getPandaList({
      keyWord: value ? value : undefined,
      limit: that.limit,
      page,
      scene,
      color: "全部",
      style
    }).then((data) => {
      const { currPage, limit, pageCount, count } = data;
      that.page = page;
      that.limit = limit;
      that.count = count;
      setList(data.dataList);
      that.fetchListOver = currPage === pageCount;
      update();
    }).catch((e) => {
      message.error("数据加载错误");
    }).finally(() => {
      that.loading = false;
      update();
    });

  };
  const onClickItem = (item) => {
    that.target = item;
    setVisible2(true);
  };
  const onBack = () => {
    that.target = null;
    setVisible2(false);
  };
  const init = async () => {
    await fetchTag();
    setTimeout(() => {
      fetchList();
    }, 100);
  };
  const onChangeInput = (e) => {
    const value = e.currentTarget.value;
    setValue(value);
  };
  const onEnterInput = (e) => {
    const value = e.currentTarget.value;
    fetchList(value, 1);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      onEnterInput(e);
    }
  };
  const onClickSearchButton = () => {
    fetchList(value, 1);
  };
  const onScroll = (e) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight && !that.loading && !that.fetchListOver) {
      fetchList(value, that.page + 1);
    }
  };
  const onChangePage = (page) => {
    fetchList(value, page);
  };
  const targetImageList = useMemo(() => that.target?.pptPageThemeImage?.map?.((item) => item.ossUrl), [that.target]);
  const targetName = useMemo(() => that.target?.name, [that.target]);
  const onClickBack = () => {
    if (visible2) {
      setVisible2(false);
    } else {
      props.back?.();
    }
  };

  useEffect(() => {
    init();
  }, []);
  console.log(visible2);
  return <Styled>
    {
      !visible2 && <FlexStyled style={{ margin: 40 }}>
        <BackBoxStyled onClick={onClickBack} alignItems="center" style={{ marginBottom: 20 }}>
          {leftIcon}
          <BackStyled style={{ marginLeft: 6 }}>返回</BackStyled>
        </BackBoxStyled>
      </FlexStyled>
    }

    {
      visible2 ? <Content2 {...props}
                           onClickApply={onClickApply}
                           target={that.target} type={type} back={onBack} list={targetImageList}
                           name={targetName} /> : <Styled9>
        <FlexStyled flexShrink={0} justifyContent="center">
          <Styled2>
            <input onKeyDown={onKeyDown} onChange={onChangeInput} placeholder="在海量精美模版中搜索" />
            <Styled3 onClick={onClickSearchButton}>
              {searchIcon}
            </Styled3>
          </Styled2>
        </FlexStyled>
        <FlexStyled flexDirection="column" flexShrink={0} justifyContent="center"
                    style={{ padding: "0 26px", marginTop: 28 }}>
          {
            that.tagLoading ? <FlexStyled alignItems="center" justifyContent="center" style={{ width: "100%" }}>
              <Spin />
            </FlexStyled> : tabs.map((item, index) => {
              return <TagContainer key={item.id} item={item} onClickTag={onClickTag} tabIndex={tabIndex}
                                   index={index} />;
            })
          }
        </FlexStyled>
        <Styled5>
          {
            that.loading ? <FlexStyled alignItems="center" justifyContent="center" style={{ width: "100%" }}>
              <Spin />
            </FlexStyled> : <>
              {
                list.map((item) => {
                  return <Styled6 key={item.id} onClick={() => onClickItem(item)} width={224} height={126}
                                  src={item.pptPageThemeImage?.[0]?.ossUrl} />;
                })
              }
              <FlexStyled justifyContent="center" style={{ margin: "20px 0", width: "100%" }}>
                <Pagination responsive
                            showSizeChanger={false}
                            hideOnSinglePage
                            defaultCurrent={1}
                            current={that.page}
                            defaultPageSize={that.limit}
                            pageSize={that.limit}
                            total={that.count}
                            onChange={onChangePage}
                />
              </FlexStyled>
            </>
          }
        </Styled5>
      </Styled9>
    }
  </Styled>;

};

