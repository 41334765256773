import { useEffect } from 'react';
import dragula from 'dragula';
import useDragScroll from './useDragScroll';

export default (props) => {
  const { getScrollContainer, getListContainer, isPre, isNext, onShadow, onDrop ,speed} = props;
  const { onDragStart, onDragOver } = useDragScroll({ getScrollContainer, isPre, isNext,speed });
  useEffect(() => {
    let drake;
    drake = dragula([getListContainer()], {
      moves: function(el, source, handle, sibling) {
        return handle?.getAttribute('id') === 'drag-button';
      },
    });
    drake.on('drag', (el, container, source, sibling) => {
      onDragStart();
    });
    drake.on('dragend', (el, container, source, sibling) => {
      onDragOver();
    });
    drake.on('shadow', (el, container, source, sibling) => {
      onShadow?.();
    });
    drake.on('drop', (el, container, source, sibling) => {
      onDrop?.(el, container, source, sibling);
    });
    return () => {
      drake?.destroy?.();
    };
  }, [getListContainer]);
}
