export const INodeType = {
  text: "text",
  image: "image",
  svg: "svg",
  line: "line",
  charts: "charts",
  layout: "layout",
  table: "table"
};

export const INode = {
  type: INodeType
};
export default INode;

