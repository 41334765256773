export default (value, html) => {
  if (!html) {
    return value;
  }
  let index = 0;
  let lastTextNode;
  if (html.indexOf('<ul') !== -1 || html.indexOf('<ol') !== -1) {
    const dom = new DOMParser().parseFromString(html, 'text/html');
    html = html.replace(/\n/g, '');
    const arr = dom.getElementsByTagName('ul');
    arr.forEach((ul) => {
      const value = ul.innerText;
      let pStyle;
      let sStyle;
      if (ul?.firstElementChild?.getAttribute?.('style')) {
        pStyle = ul?.firstElementChild?.getAttribute?.('style');
        const spanArr = ul?.firstElementChild.getElementsByTagName('span');
        const span = spanArr[0];
        if (span) {
          sStyle = span?.getAttribute?.('style');
        }
      }
      html = `<p style='${pStyle}'><span style='${sStyle}'>${value}</span></p>`;
    });
  }
  const dom = new DOMParser().parseFromString(html, 'text/html');
  let sStyle;
  const spanArr = dom.getElementsByTagName('span');
  const span = spanArr[0];
  if (spanArr[0]) {
    sStyle = span?.getAttribute?.('style');
  }
  const changeFontSize = (element) => {
    if (!element) {
      return;
    }
    element?.childNodes.forEach((child) => {
      if (child.nodeName === 'SPAN') {
        child.setAttribute('style', sStyle);
      }
      if (child?.nodeType === 3) {
        if (child.nodeValue) {
          if (index >= value.length) {
            child.nodeValue = '';
          } else {
            child.nodeValue = value.slice(index, index + child.nodeValue.length);
            index += child.nodeValue.length;
          }
        }
        lastTextNode = child;
      }
      changeFontSize(child);
    });
  };
  changeFontSize(dom.body);
  if (lastTextNode) {
    lastTextNode.nodeValue = lastTextNode.nodeValue + value.slice(index, value.length);
  }
  return dom.body.innerHTML;
};
