import request from "../utils/request";
import getBaseUrl from "../utils/base_url";
import { strictStringify } from "../common";
import store from "store";

export async function gzhState(payload) {
  return request.get(getBaseUrl() + `/ppt/login/wechat`, payload);
}

export async function getUserInfo(payload) {
  return request.get(getBaseUrl() + `/ppt/user/token`, payload);
}

export async function login_anonymous(payload, query) {
  return request.get(getBaseUrl() + `/ppt/user/login_anonymous` + strictStringify(query, true), payload);
}

export async function gzhQRCode(payload) {
  let url;
  url = "/ppt/login/wechat/qr";
  return request.get(getBaseUrl() + url, payload);
}
export async function getAiPPTByKeywordByTemplatePercentage(payload, query) {
  return request.get(
    getBaseUrl() + "/ppt/ai/getAiPPTByKeywordByTemplatePercentage" + strictStringify(query, true),
    payload
  );
}
export async function getCatalog(payload, query) {
  return request.post(getBaseUrl() + `/ppt/ai/gpt35/getCatalog` + strictStringify(query, true), payload);
}
export async function getIntelligentTemplateTheme(payload, query) {
  return request.get(
    getBaseUrl() + `/ppt/templateStation/getIntelligentTemplateTheme` + strictStringify(query, true),
    payload
  );
}
export async function getPageListByCatalog(payload, query) {
  return request.post(getBaseUrl() + `/ppt/ai/gpt35/getPageListByCatalog` + strictStringify(query, true), payload);
}
export async function getPagesByPptxId(payload) {
  return request.get(getBaseUrl() + "/ppt/page/list", payload).then((res) => {
    return res;
  });
}
export async function changeTemplate(payload, query) {
  return request.post(getBaseUrl() + `/ppt/templateStation/changeTemplate` + strictStringify(query, true), payload);
}
export async function deleteThenSaveBatch(payload, query) {
  return request.post(getBaseUrl() + `/ppt/page/v2/deleteThenSaveBatch` + strictStringify(query, true), payload);
}
export async function getEditableId(payload, query) {
  return request.post(getBaseUrl() + `/ppt/templateStation/getEditableId` + strictStringify(query, true), payload);
}
export function getFilterCriteria() {
  return request.get(getBaseUrl() + `/ppt/templateStation/getFilterCriteria`);
}
export function getPandaList(data) {
  return request.get(getBaseUrl() + `/ppt/templateStation/search` + strictStringify(data, true));
}
export async function getPptxById(payload) {
  return request.get(getBaseUrl() + "/ppt/ppt/get", payload);
}
export async function getPptNotDom(payload, query) {
  return request.post(getBaseUrl() + "/plug/getPptNotDom" + strictStringify(query, true), payload);
}

// {phone: xxx}
export async function mobileSendCode(payload) {
  return request.get(getBaseUrl() + `/shortMessage/sendCode`, payload);
}

// {code: xxx, phone: yyy}
export async function mobileCheckCode(payload) {
  return request.get(getBaseUrl() + `/shortMessage/checkCode`, payload);
}

// {USER-ID: '', code: xxx, phone: yyy}
export async function mobilePhoneLogin(payload) {
  // 登录完之后
  const user = await request.get(getBaseUrl() + `/shortMessage/phoneCodeLogin`, payload);
  store.set("user", user);
  return getUserInfo();
}

// export async function mobilePhoneLogin(payload) {
//   // 登录完之后
//   const user = await request.get(getBaseUrl() + `/shortMessage/phoneCodeLogin`, payload);
//   store.set('user', user)
//   return getUserInfo()
// }
