import getNodeMarkKeyById from './getNodeMarkKeyById';
import { coverBoxType } from './replacePpt';
import { sectionBoxType } from './replacePpt';
import { catalogueBoxType } from './replacePpt';
import { lastBoxType } from './replacePpt';
import { contentBoxType } from './replacePpt';
import getNodes from './getNodes';


export const createCatalogPage = (props) => {
  return {
    nodeMark: {
      catalogTitle: ['42c871bb-a570-491e-bef8-0c5ad169b4ac'],
      catalog: [
        {
          title: { id: '6274791e-4a1b-4ae2-ad4e-07d51d30faee' },
        },
        {
          title: { id: 'f296a53f-fb30-4a1c-bdfe-3daa7595af6d' },
        },
        {
          title: { id: '609b8a98-cafb-40b7-b430-880d5e50b606' },
        },
        {
          title: { id: '02236fe4-3432-43da-bbfb-1193df8c5a66' },
        },
      ],
    },
    type: 'catalog',
    'nodes': {
      '42c871bb-a570-491e-bef8-0c5ad169b4ac': { 'transform': { 'rotate': 0, 'translateY': -32.26888404702238, 'translateX': -192.45283018867858 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 91.30606415094336, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 2 }, 'id': '42c871bb-a570-491e-bef8-0c5ad169b4ac', 'type': 'text', 'value': '<p><span style="font-size: 40px;"><strong>目录</strong></span></p>' },
      '6274791e-4a1b-4ae2-ad4e-07d51d30faee': { 'transform': { 'rotate': 0, 'translateY': -126.22641509433939, 'translateX': 128.24265028521344 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 330.0019534883717, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 4 }, 'id': '6274791e-4a1b-4ae2-ad4e-07d51d30faee', 'type': 'text', 'value': '<p><span style="font-size: 26px;"><strong>01. 输入目录内容</strong></span></p>' },
      '66b8859a-9293-4780-8ef6-f6693199fdc7': { 'strokeWidth': 1, 'transform': { 'translateY': -10.836112544221693, 'translateX': 88.86502247936306 }, 'width': 200, 'style': { 'top': 182.26415094339623, 'left': 238.6821933962264, 'width': 2.2641509433962312, 'position': 'absolute', 'height': 82.0754716981132, 'zIndex': 3 }, 'id': '66b8859a-9293-4780-8ef6-f6693199fdc7', 'type': 'svg', 'fill': '#e4e4e4', 'value': '<svg viewBox=\'0 0 1024 1024\'  width=\'200\' height=\'200\'><path d=\'M0 0h1024v1024h-1024v-1024z\'></path></svg>', 'stroke': '#000000', 'height': 200 },
      '02236fe4-3432-43da-bbfb-1193df8c5a66': { 'transform': { 'rotate': 0, 'translateY': 71.44800351031182, 'translateX': 68.24260770254628 }, 'textScale': 1, 'style': { 'top': 274, 'left': 360, 'width': 330.0019534883717, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 7 }, 'id': '02236fe4-3432-43da-bbfb-1193df8c5a66', 'type': 'text', 'value': '<p><span style="font-size: 26px;"><strong>04. 输入目录内容</strong></span></p>' },
      'f296a53f-fb30-4a1c-bdfe-3daa7595af6d': { 'transform': { 'rotate': 0, 'translateY': -62.57194575953741, 'translateX': 108.24258286265714 }, 'textScale': 1, 'style': { 'top': 234, 'left': 320, 'width': 330.0019534883717, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 5 }, 'id': 'f296a53f-fb30-4a1c-bdfe-3daa7595af6d', 'type': 'text', 'value': '<p><span style="font-size: 26px;"><strong>02. 输入目录内容</strong></span></p>' },
      '609b8a98-cafb-40b7-b430-880d5e50b606': { 'transform': { 'rotate': 0, 'translateY': 4.587538394032701, 'translateX': 88.24265738232462 }, 'textScale': 1, 'style': { 'top': 254, 'left': 340, 'width': 330.0019534883717, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 6 }, 'id': '609b8a98-cafb-40b7-b430-880d5e50b606', 'type': 'text', 'value': '<p><span style="font-size: 26px;"><strong>03. 输入目录内容</strong></span></p>' },
    },
    'style': {},
  };
};
export const createContentPage = (props) => {
  return {
    nodeMark: {
      pageTitle: ['3076d4b8-6a64-4b01-b29c-7c32e0031475'],
      describe: ['5f169039-55db-4304-b45b-7c7e287f2b99'],
      point: [
        {
          title: { id: '5f169039-55db-4304-b45b-7c7e287f2b10' },
          valueNodes: [
            { id: '5f169039-55db-4304-b45b-7c7e287f2b11' },
          ],
        },
        {
          title: { id: '5f169039-55db-4304-b45b-7c7e287f2b12' },
          valueNodes: [
            { id: '5f169039-55db-4304-b45b-7c7e287f2b13' },
          ],
        },
        {
          title: { id: '5f169039-55db-4304-b45b-7c7e287f2b14' },
          valueNodes: [
            { id: '5f169039-55db-4304-b45b-7c7e287f2b15' },
          ],
        },
      ],
    },
    type: 'main',
    'nodes': {
      '3076d4b8-6a64-4b01-b29c-7c32e0031475': { 'transform': { 'translateY': -267.7358490566033, 'translateX': 41.886792452830285 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 24, 'textScale': 1, 'position': 'absolute', 'height': 32.5, 'zIndex': 4 }, 'id': '3076d4b8-6a64-4b01-b29c-7c32e0031475', 'type': 'text', 'value': '<p><span style="font-size: 24px;"><strong>输入幻灯片标题</strong></span></p>' },
      '461c5a55-5e40-4740-b277-aca762cde377': { 'strokeWidth': 1, 'transform': { 'rotate': 0, 'translateY': -45.283018867924646, 'translateX': -14.531250000001412 }, 'width': 200, 'style': { 'top': 159.62264150943398, 'left': 15.663325471698114, 'width': 290.359, 'position': 'absolute', 'height': 249.044471698113, 'zIndex': 6 }, 'id': '461c5a55-5e40-4740-b277-aca762cde377', 'type': 'svg', 'fill': '#e4e4e4', 'value': '<svg viewBox=\'0 0 1024 1024\'  width=\'200\' height=\'200\'><path d=\'M0 0h1024v1024h-1024v-1024z\'></path></svg>', 'stroke': '#e4e4e4', 'height': 200 },
      '6dc94508-c13c-4e07-9169-e91be6e21355': { 'transform': { 'translateY': -189.62264150943346, 'translateX': -275.66036008438977 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 20, 'textScale': 1, 'position': 'absolute', 'height': 26.5, 'zIndex': 9 }, 'id': '6dc94508-c13c-4e07-9169-e91be6e21355', 'type': 'text', 'value': '<p><span style="font-size: 20px;"><strong>输入幻灯片标题</strong></span></p>' },
      '5f169039-55db-4304-b45b-7c7e287f2b99': { 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b99', 'type': 'text', 'value': '<p><strong>输入正文描述</strong></p>' },
      '3ed6684c-7aa6-42f2-898a-1160c28fe6b8': { 'originHeight': 512, 'transform': { 'rotate': 0, 'translateY': 29.9999999999999, 'translateX': -229.6226184772985 }, 'originWidth': 640, 'style': { 'top': 105, 'left': 250, 'width': 300, 'index': 0, 'position': 'absolute', 'height': 240, 'zIndex': 7 }, 'id': '3ed6684c-7aa6-42f2-898a-1160c28fe6b8', 'type': 'image', 'value': 'https://pub.jianzeppt.cn/image/islide/226342.jpg?x-oss-process=image/auto-orient,1/interlace,1/format,jpg', 'imageTransform': {} },
      'ca17b028-024a-40e4-918c-d75f236e00b5': { 'strokeWidth': 1, 'transform': {}, 'width': 200, 'style': { 'top': 360.5660377358491, 'left': 6.0406839622641515, 'width': 0, 'position': 'absolute', 'height': 0, 'zIndex': 5 }, 'id': 'ca17b028-024a-40e4-918c-d75f236e00b5', 'type': 'svg', 'fill': '#000000', 'value': '<svg viewBox=\'0 0 1024 1024\'  width=\'200\' height=\'200\'><path d=\'M0 0h1024v1024h-1024v-1024z\'></path></svg>', 'stroke': '#000000', 'height': 200 },
      '5f169039-55db-4304-b45b-7c7e287f2b10': { 'value': '<p><strong>要点标题1</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b10', 'type': 'text' },
      '5f169039-55db-4304-b45b-7c7e287f2b11': { 'value': '<p><strong>请在此输入你希望展示的内容</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b11', 'type': 'text' },
      '5f169039-55db-4304-b45b-7c7e287f2b12': { 'value': '<p><strong>要点标题2</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b12', 'type': 'text' },
      '5f169039-55db-4304-b45b-7c7e287f2b13': { 'value': '<p><strong>请在此输入你希望展示的内容</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b13', 'type': 'text' },
      '5f169039-55db-4304-b45b-7c7e287f2b14': { 'value': '<p><strong>要点标题3</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b14', 'type': 'text' },
      '5f169039-55db-4304-b45b-7c7e287f2b15': { 'value': '<p><strong>请在此输入你希望展示的内容</strong></p>', 'transform': { 'translateY': -99.66037678268728, 'translateX': 80.94339622641475 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200, 'fontSize': 18, 'textScale': 1, 'position': 'absolute', 'height': 24, 'zIndex': 8 }, 'id': '5f169039-55db-4304-b45b-7c7e287f2b15', 'type': 'text' },

    },
    'style': {},
  };
};
export const createTransitionPage = (props) => {
  return {
    nodeMark: {
      pageTitle: '117de6b7-c1d0-4c00-b119-3133056d3157',
      partNum: ['698ad7fc-becd-4fa3-b2d1-981e1a5c42d4'],
    },
    type: 'transition',
    'nodes': {
      'd66d24e3-fd03-4b97-9c8b-90bfa735e843': { 'strokeWidth': 1, 'transform': { 'translateY': 26.037735849056613, 'translateX': 50.660135521079006 }, 'width': 200, 'style': { 'top': 50.943396226415096, 'left': 296.4180424528302, 'width': 105.84905660377359, 'position': 'absolute', 'height': 99.62264150943396, 'zIndex': 2 }, 'id': 'd66d24e3-fd03-4b97-9c8b-90bfa735e843', 'type': 'svg', 'fill': '#e4e4e4', 'value': '<svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2940" width="200" height="200"><path d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z" ></path></svg>', 'stroke': '#e4e4e4', 'height': 200 },
      '117de6b7-c1d0-4c00-b119-3133056d3157': { 'transform': { 'rotate': 0, 'translateY': 37.45753162312061, 'translateX': -20.648337310215197 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 241.30373584905647, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 4 }, 'id': '117de6b7-c1d0-4c00-b119-3133056d3157', 'type': 'text', 'value': '<p><span style="font-size: 40px;"><strong>输入章节标题</strong></span></p>' },
      '698ad7fc-becd-4fa3-b2d1-981e1a5c42d4': { 'transform': { 'rotate': 0, 'translateY': -113.71417927292146, 'translateX': 76.70320906729071 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 46.60377358490565, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53, 'zIndex': 3 }, 'id': '698ad7fc-becd-4fa3-b2d1-981e1a5c42d4', 'type': 'text', 'value': '<p><span style="font-size: 40px;"><strong>01</strong></span></p>' },
    },
    'style': {},
  };
};
export const createCoverPage = (props) => {
  return {
    nodeMark: {
      pageTitle: ['117de6b7-c1d0-4c00-b119-3133056d3157'],
    },
    type: 'cover',
    'nodes': { '117de6b7-c1d0-4c00-b119-3133056d3157': { 'transform': { 'rotate': 0, 'translateY': -15.749998272589853, 'translateX': -40.17183699697813 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 280.349566037735, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 4 }, 'id': '117de6b7-c1d0-4c00-b119-3133056d3157', 'type': 'text', 'value': '<p><span style="font-size: 40px;"><strong>输入幻灯片标题</strong></span></p>' } },
    'style': {},
  };
};
export const createEndPage = (props) => {
  return {
    nodeMark: {
      pageTitle: ['117de6b7-c1d0-4c00-b119-3133056d3157'],
    },
    type: 'end',
    'nodes': { '117de6b7-c1d0-4c00-b119-3133056d3157': { 'transform': { 'rotate': 0, 'translateY': -15.749989635539446, 'translateX': -0.2655893001915608 }, 'textScale': 1, 'style': { 'top': 214, 'left': 300, 'width': 200.5326792452826, 'fontSize': 40, 'textScale': 1, 'position': 'absolute', 'height': 53.5, 'zIndex': 4 }, 'id': '117de6b7-c1d0-4c00-b119-3133056d3157', 'type': 'text', 'value': '<p><span style="font-size: 40px;"><strong>输入结束语</strong></span></p>' } },
    'style': {},
  };
};
export const placeholder = {
  cover: {
    title: '输入15字以内的幻灯片标题',
    des: '请输入副标题',
    author: '输入汇报人信息或其他描述',
    date: '输入汇报日期或其他信息',
    other: '输入内容',
  },
  catalogue: {
    title: '目录标题',
    list: '输入目录内容',
  },
  section: {
    num: '输入章节编号，例如01',
    title: '输入章节标题',
  },
  content: {
    title: '输入15字以内的幻灯片标题',
    title2: '输入15字以内的幻灯片副标题',
    des: '输入200字以内的正文描述',
    listDes: '输入要点描述',
    listTitle: '标题',
  },
  last: {
    title: '输入结束语',
  },
  default: '请输入内容',
};


export const coverPageTransformOldPage = (page) => {
  const nodeMark = page.nodeMark;
  const op = {};
  if (nodeMark) {
    op.Index = {
      value: {
        header: {
          value: undefined,
        },
        description: {
          value: undefined,
        },
        date: {
          value: undefined,
        },
        author: {
          value: undefined,
        },
      },
    };
    if (nodeMark.pageTitle?.[0]) {
      const node = page.nodes[nodeMark.pageTitle?.[0]];
      op.Index.value.header.value = node?.value;
      op.Index.value.header.id = node?.id;
    }
    if (nodeMark.subtitle?.[0]) {
      const node = page.nodes[nodeMark.subtitle?.[0]];
      op.Index.value.description.value = node?.value;
      op.Index.value.description.id = node?.id;

    }
    if (nodeMark.author?.[0]) {
      const node = page.nodes[nodeMark.author?.[0]];
      op.Index.value.author.value = node?.value;
      op.Index.value.author.id = node?.id;

    }
    if (nodeMark.date?.[0]) {
      const node = page.nodes[nodeMark.date?.[0]];
      op.Index.value.date.value = node?.value;
      op.Index.value.date.id = node?.id;
    }
  }
  return op;
};
export const catalogPageTransformOldPage = (page) => {
  const nodeMark = page.nodeMark;
  const nodes = page.nodes;

  const op = {};
  if (nodeMark) {
    if (nodeMark.catalogTitle?.[0]) {
      const node = page.nodes[nodeMark.catalogTitle?.[0]];
      op.header = {
        value: {
          header: {
            value: node?.value,
            id: node?.id,
          },
        },
      };
    }
    if (nodeMark.catalog) {
      op.List = {
        value: {},
      };
      nodeMark.catalog.forEach((item, index) => {
        op.List.value[index + 1] = {
          value: {
            header: {
              value: nodes[item?.title?.id]?.value,
              id: nodes[item?.title?.id]?.id,

            },
            description: {
              value: item?.valueNodes?.map?.((i) => nodes[i?.id])?.[0]?.value,
              id: item?.valueNodes?.map?.((i) => nodes[i?.id])?.[0]?.id,
            },
            number: {
              value: index + 1,
            },
          },
        };

      });
    }
  }
  return op;
};
export const transitionPageTransformOldPage = (page) => {
  const nodeMark = page.nodeMark;
  const op = {
    Transition: {
      value: {
        header: {
          value: undefined,
        },
        number: {
          value: undefined,
        },
      },
    },
  };
  if (nodeMark) {
    if (nodeMark.pageTitle) {
      const node = page.nodes[nodeMark.pageTitle];
      op.Transition.value.header.value = node?.value;
      op.Transition.value.header.id = node?.id;
    }
    if (nodeMark.partNum?.[0]) {
      const node = page.nodes[nodeMark.partNum?.[0]];
      op.Transition.value.number.value = node?.value;
      op.Transition.value.number.id = node?.id;
    }
  }
  return op;
};
export const contentPageTransformOldPage = (page) => {
  const nodeMark = page.nodeMark;
  const nodes = page.nodes;
  const op = {};
  //标题
  if (nodeMark.pageTitle) {
    const node = page.nodes[nodeMark.pageTitle];
    op.header = {
      value: {
        header: {
          value: node?.value,
          id: node?.id,
        },
      },
    };
  }
  //正文
  if (nodeMark.describe?.[0]) {
    const node = page.nodes[nodeMark.describe?.[0]];
    op.describe = {
      'body-top': {
        value: {
          text: {
            value: node?.value,
            id: node?.id,
          },
        },
      },
    };
  }

  if (nodeMark.point) {
    op.List = { value: {} };
    nodeMark.point.forEach((item, index) => {
      const title = nodes[item?.title?.id];
      const des = item.valueNodes?.map?.((i) => nodes[i?.id])?.[0];
      const plate = item.plate?.map?.((id) => nodes[id])?.[0];
      op.List.value[index + 1] = {
        value: {
          header: {
            value: title?.value,
            id: title?.id,
          },
          description: {
            value: des?.value,
            id: des?.id,
          },
          image: {
            value: plate?.value,
            id: plate?.id,
          },
        },
      };
    });
  }

  if (nodeMark.plate) {
    op.plate = {
      value: {},
    };
    nodeMark.plate?.forEach((id, index) => {
      const node = nodes[id];
      op.plate.value[index + 1] = {
        value: {
          image: {
            value: node?.value,
            id: node?.id,
          },
        },
      };
    });
  }
  if (nodeMark.charts) {
    op.charts = {
      value: {},
    };
    nodeMark.charts?.forEach((id, index) => {
      const node = nodes[id];
      op.charts.value[index + 1] = {
        value: {
          charts: {
            value: node?.value,
            id: node?.id,
          },
        },
      };
    });
  }

  return op;
};
export const endPageTransformOldPage = (page) => {
  const nodeMark = page.nodeMark;
  const nodes = page.nodes;
  const op = {};
  //标题
  if (nodeMark.pageTitle) {
    const node = page.nodes[nodeMark.pageTitle];
    op.header = {
      value: {
        header: {
          value: node?.value,
          id: node?.id,
        },
      },
    };
  }


  return op;
};
export const pageTransformOldPage = (page) => {
  let np;
  switch (page.type) {
    case 'cover': {
      np = coverPageTransformOldPage(page);
      break;
    }
    case 'catalog': {
      np = catalogPageTransformOldPage(page);
      break;
    }
    case 'transition': {
      np = transitionPageTransformOldPage(page);
      break;
    }
    case 'main': {
      np = contentPageTransformOldPage(page);
      break;
    }
    case 'end': {
      np = endPageTransformOldPage(page);
      break;
    }
  }
  if (np) {
    np.id = page.id;
  }
  if (page.icons && np?.List?.value) {
    page.icons.forEach((icon, index) => {
      if (np?.List?.value?.[index + 1]?.value) {
        np.List.value[index + 1].value.icon = {
          value: icon,
        };
      }
    });
  }
  return np;
};
export const getNodeTypeNodeMark = (page, node) => {
  const path = getNodeMarkKeyById(page.nodeMark, node?.id);
  // console.log(path);
  switch (page.type) {
    case 'cover': {
      if (path?.indexOf('pageTitle[') !== -1) {
        return coverBoxType.title;
      } else if (path?.indexOf('subtitle[') !== -1) {
        return coverBoxType.des;
      } else if (path?.indexOf('author[') !== -1) {
        return coverBoxType.author;
      } else if (path?.indexOf('date[') !== -1) {
        return coverBoxType.date;
      } else {
        return sectionBoxType.other;
      }
    }
    case 'catalog': {
      if (path?.indexOf('catalogTitle') !== -1) {
        return catalogueBoxType.title;
      } else if (path?.indexOf('catalog[') !== -1) {
        return catalogueBoxType.list;
      } else {
        return sectionBoxType.other;
      }
    }
    case 'transition': {
      if (path?.indexOf('pageTitle') !== -1) {
        return sectionBoxType.title;
      } else if (path?.indexOf('partNum') !== -1) {
        return sectionBoxType.partNum;
      } else {
        return sectionBoxType.other;
      }
    }
    case 'end': {
      if (path?.indexOf('pageTitle[') !== -1) {
        return lastBoxType.title;
      } else {
        return lastBoxType.other;
      }
    }
    case 'main': {
      if (path?.indexOf('pageTitle') !== -1) {
        return contentBoxType.title;
      } else if (path?.indexOf('describe[') !== -1) {
        return contentBoxType.main;
      } else if (path?.indexOf('point[') !== -1) {
        return contentBoxType.list;
      } else if (path?.indexOf('plate[') !== -1) {
        return contentBoxType.plate;
      } else if (path?.indexOf('charts[') !== -1) {
        return contentBoxType.chart;
      } else {
        return contentBoxType.other;
      }
    }
  }
};


export const getPointNodes = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  return page.nodeMark.point?.map?.((item) => ({
    title: nodes[item?.title?.id],
    description: item.valueNodes?.map?.((i) => nodes[i?.id])?.[0],
    plate: item.plate?.map?.((id) => nodes[id])?.[0],
  }));
};
export const getMainNode = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return nodes[nodeMark?.describe?.[0]];
};
export const getTitleNode = (page, theme) => {
  const nodes = getNodes({ page, theme: theme });
  const nodeMark = page.nodeMark;
  return nodes[nodeMark.pageTitle];
};



