import React, { useRef } from "react";
import styled from "styled-components";
import SearchInput from "../SearchInput";
import useForceUpdate from "../../hooks/useForceUpdate";
import FlexStyled from "../../styled/FlexStyled";
import useSyllabus from "../../hooks/useSyllabus";
import withComponent from "../../hoc/withComponent";

const TextStyled = styled.div`
  font-size: 26px;
  font-family: PingFangSC-Medium, "PingFang SC";
  font-weight: 500;
  color: rgb(102, 102, 102);
  line-height: 26px;
  letter-spacing: 4px;
  text-align: center;
`;
const ButtonStyled = styled.div`
  flex-shrink: 0;
  width: 104px;
  height: 34px;
  background: rgb(17, 187, 166);
  border-radius: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, "PingFang SC";
  font-weight: 400;
  color: rgb(255, 255, 255);
  line-height: 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
`;
const SearchStyled = styled.div`
  overflow: hidden;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 364px;
  height: 50px;
  padding-left: 5px;
  padding-right: 8px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(210 210 210 / 50%) 0px 2px 10px 0px;
  border-radius: 25px;
`;
const TagStyled = styled.div`
  padding: 9px 16px;
  min-height: 12px;
  border-radius: 15px;
  border: 1px solid rgb(236, 236, 236);
  font-size: 12px;
  line-height: 12px;
  font-family: PingFangSC-Regular, "PingFang SC";
  font-weight: 400;
  color: rgb(156, 156, 156);
  cursor: pointer;
`;

export default withComponent((props) => {
  const { onSearch } = props;
  const that = useRef({
    value: "",
  }).current;
  const [, update] = useForceUpdate();
  const { fetchData, context, loading } = useSyllabus();
  const onChange = (value) => {
    that.value = value;
    update();
  };

  const search = async () => {
    const data = await fetchData(that.value);
    onSearch({ title: that.value, data });
  };

  return (
    <FlexStyled
      flexDirection="column"
      alginItems="center"
      justifyContent="center"
      style={{ height: "100%", width: "100%" }}
    >
      {loading ? (
        context
      ) : (
        <>
          <TextStyled>AI 一键生成专属PPT</TextStyled>
          <FlexStyled justifyContent="center" style={{ marginTop: 50 }}>
            <SearchStyled>
              <SearchInput value={that.value} onChange={onChange} onEnter={search} onClickAutoCompleteItem={onChange} />
              <ButtonStyled onClick={search}>生成下载</ButtonStyled>
            </SearchStyled>
          </FlexStyled>
          <FlexStyled justifyContent="center" style={{ marginTop: 50 }}>
            <TagStyled onClick={() => onChange("通用商业计划书")} style={{ marginRight: 16 }}>
              通用商业计划书
            </TagStyled>
            <TagStyled onClick={() => onChange("融资商业计划书")} style={{ marginRight: 16 }}>
              融资商业计划书
            </TagStyled>
            <TagStyled onClick={() => onChange("商业合作计划书")}>商业合作计划书</TagStyled>
          </FlexStyled>
        </>
      )}
    </FlexStyled>
  );
});
