import styled from 'styled-components';


const FlexStyled = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};
  flex: ${(props) => props.flex};
  flex-wrap: ${(props) => props.flexWrap};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
`;
export default FlexStyled;
