import withComponent from '../../../hoc/withComponent';
import styled from 'styled-components';
import Pointer from '../../../styled/Pointer';
import FlexStyled from '../../../styled/FlexStyled';
import React, { useState } from 'react';

const SyllabusEditContainerStyled = styled.div`
  & input {
    flex-shrink: 0;
    outline: none;
    border-radius: 4px;
    border: 1px solid #E5E7EB;
    padding: 0 12px;
    width: 388px;
    height: 36px;
  }

  & input::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C5C5C5;
    line-height: 14px;
  }

  & input:focus {
    border: 1px solid #2C2C2C;
  }
`;

const CircleBoxStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  left: -3px;
  background: white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

`;
const CircleStyled = styled.div`
  width: 8px;
  height: 8px;
  background: ${props => props.isActive ? '#7C7C7C' : '#D8D8D8'};
  border-radius: 50%;
  overflow: hidden;
`;
const CircleHollowStyled = styled.div`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border: ${props => props.isActive ? '1px solid #7C7C7C' : '1px solid  #D8D8D8'};
  border-radius: 50%;
  overflow: hidden;
`;
const AcrossLine = styled.div`
  width: 58px;
  height: 1px;
  flex-shrink: 0;
  background: #EEEEEE;
`;
const VerticalLine = styled.div`
  position: absolute;
  top: 28px;
  bottom: 28px;
  left: 23px;
  width: 1px;
  border: 1px solid #EEEEEE;
`;

const icon2 = <div style={{ width: 20, height: 20, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top: 0'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='新增大纲-选中状态' transform='translate(-1196.000000, -380.000000)' fill-rule='nonzero'>
            <g id='字体下拉备份-6' transform='translate(1196.000000, 380.000000)'>
                <g id='tianjia-8' transform='translate(1.666667, 1.666667)'>
                    <path d='M8.33333333,0 C12.9357096,0 16.6666667,3.73095703 16.6666667,8.33333334 C16.6666667,12.9357097 12.9357096,16.6666667 8.33333333,16.6666667 C3.73095701,16.6666667 0,12.9357096 0,8.33333334 C0,3.73095705 3.73095703,0 8.33333333,0 Z' id='形状' fill='#2C2C2C'></path>
                    <path d='M7.73811849,7.73811849 L7.73811849,4.16666666 C7.73811849,3.83805338 8.00455729,3.57145182 8.33333333,3.57145182 C8.66210936,3.57145182 8.92854816,3.83789062 8.92854818,4.16666666 L8.92854818,7.73811849 L12.5,7.73811849 C12.8286133,7.73811849 13.0952148,8.00455729 13.0952148,8.33333334 C13.0952148,8.6621094 12.828776,8.92854818 12.5,8.92854818 L8.92854818,8.92854818 L8.92854818,12.5 C8.92854818,12.8286133 8.66210938,13.0952148 8.33333333,13.0952148 C8.00472004,13.0952148 7.73811849,12.828776 7.73811849,12.5 L7.73811849,8.92854818 L4.16666666,8.92854818 C3.84065755,8.92220053 3.57779947,8.65950521 3.57145182,8.33333334 C3.57145182,8.00472006 3.83789062,7.7381185 4.16666666,7.73811849 L7.73811849,7.73811849 Z' id='路径' fill='#FFFFFF'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
const icon3 = <div style={{ width: 13, height: 20, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='13px' height='20px' viewBox='0 0 13 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='新增大纲-选中状态' transform='translate(-981.000000, -350.000000)' fill='#999999' fill-rule='nonzero'>
            <g id='字体下拉备份-8' transform='translate(981.000000, 350.000000)'>
                <g id='tuozhuai' transform='translate(3.260000, 5.000000)'>
                    <path d='M1.15384615,7.77777778 C1.79109779,7.77777778 2.30769231,8.27523917 2.30769231,8.88888889 C2.30769231,9.50253861 1.79109779,10 1.15384615,10 C0.516594519,10 0,9.50253861 0,8.88888889 C0,8.27523917 0.516594519,7.77777778 1.15384615,7.77777778 Z M5.32051282,7.77777778 C5.95776445,7.77777778 6.47435897,8.27523917 6.47435897,8.88888889 C6.47435897,9.50253861 5.95776445,10 5.32051282,10 C4.68326119,10 4.16666667,9.50253861 4.16666667,8.88888889 C4.16666667,8.27523917 4.68326119,7.77777778 5.32051282,7.77777778 Z M1.15384615,3.88888889 C1.79109779,3.88888889 2.30769231,4.38635028 2.30769231,5 C2.30769231,5.61364972 1.79109779,6.11111111 1.15384615,6.11111111 C0.516594519,6.11111111 0,5.61364972 0,5 C0,4.38635028 0.516594519,3.88888889 1.15384615,3.88888889 Z M5.32051282,3.88888889 C5.95776445,3.88888889 6.47435897,4.38635028 6.47435897,5 C6.47435897,5.61364972 5.95776445,6.11111111 5.32051282,6.11111111 C4.68326119,6.11111111 4.16666667,5.61364972 4.16666667,5 C4.16666667,4.38635028 4.68326119,3.88888889 5.32051282,3.88888889 Z M1.15384615,0 C1.79109779,0 2.30769231,0.497461389 2.30769231,1.11111111 C2.30769231,1.72476083 1.79109779,2.22222222 1.15384615,2.22222222 C0.516594519,2.22222222 0,1.72476083 0,1.11111111 C0,0.497461389 0.516594519,0 1.15384615,0 Z M5.32051282,0 C5.95776445,0 6.47435897,0.497461389 6.47435897,1.11111111 C6.47435897,1.72476083 5.95776445,2.22222222 5.32051282,2.22222222 C4.68326119,2.22222222 4.16666667,1.72476083 4.16666667,1.11111111 C4.16666667,0.497461389 4.68326119,0 5.32051282,0 Z' id='形状'></path>
                </g>
            </g>
        </g>
    </g>
</svg>`,
}} />;
const icon = <div style={{ width: 20, height: 20, position: 'relative' }} dangerouslySetInnerHTML={{
  __html: `<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='position: absolute;top: 0'>
    <g id='输入主题，流程弹窗◉◉' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g id='新增大纲-选中状态' transform='translate(-1415.000000, -350.000000)' fill='#2C2C2C' fill-rule='nonzero' stroke='#FFFFFF' stroke-width='0.2'>
            <g id='字体下拉备份-7' transform='translate(1413.214887, 348.214887)'>
                <path d='M11.1898982,11.1898982 L11.1898982,7.61844635 C11.1898982,7.28983307 11.456337,7.02323151 11.785113,7.02323151 C12.113889,7.02323151 12.3803279,7.28967031 12.3803279,7.61844635 L12.3803279,11.1898982 L15.9517797,11.1898982 C16.280393,11.1898982 16.5469945,11.456337 16.5469945,11.785113 C16.5469945,12.1138891 16.2805557,12.3803279 15.9517797,12.3803279 L12.3803279,12.3803279 L12.3803279,15.9517797 C12.3803279,16.280393 12.1138891,16.5469945 11.785113,16.5469945 C11.4564997,16.5469945 11.1898982,16.2805557 11.1898982,15.9517797 L11.1898982,12.3803279 L7.61844635,12.3803279 C7.29243723,12.3739802 7.02957916,12.1112849 7.02323151,11.785113 C7.02323151,11.4564997 7.28967031,11.1898982 7.61844635,11.1898982 L11.1898982,11.1898982 Z M11.785113,3.45177969 C16.3874893,3.45177969 20.1184464,7.18273672 20.1184464,11.785113 C20.1184464,16.3874893 16.3874893,20.1184464 11.785113,20.1184464 C7.1827367,20.1184464 3.45177969,16.3874893 3.45177969,11.785113 C3.45177969,7.18273673 7.18273672,3.45177969 11.785113,3.45177969 Z M11.785113,18.9280167 C15.7301,18.9280167 18.9280167,15.7301 18.9280167,11.785113 C18.9280167,7.84061433 15.7297745,4.64220938 11.785113,4.64220938 C7.84061432,4.64220938 4.64220936,7.84045156 4.64220936,11.785113 C4.64220936,15.7297745 7.84045155,18.9280167 11.785113,18.9280167 Z' id='形状' transform='translate(11.785113, 11.785113) rotate(45.000000) translate(-11.785113, -11.785113) '></path>
            </g>
        </g>
    </g>
</svg>`,
}} />;
const Line = (props) => {
  const { onClick, index2 } = props;
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => {
    setHover(true);
  };
  const onMouseLeave = () => {
    setHover(false);
  };
  return <FlexStyled onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} alignItems='center' style={{ height: 24, position: 'relative', opacity: hover ? 1 : 0, visibility: index2 === 0 ? 'hidden' : undefined }}>
    <div style={{ height: 1, background: '#2C2C2C', width: '100%' }} />
    <FlexStyled justifyContent='center' style={{ position: 'absolute', left: 0, right: 0, pointerEvents: 'none', marginLeft: 42, marginRight: 45 }}>
      <Pointer onClick={onClick} style={{ pointerEvents: hover ? 'auto' : 'none' }}>
        {icon2}
      </Pointer>
    </FlexStyled>
  </FlexStyled>;
};

export default withComponent((props) => {
  const { id, index, onClickDelete, onChange, onClickAdd, isSecondLevel, showAdd = true, showBottomAdd, onClickAddBottom, showDelete, index2 } = props;
  const [isFocus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  let alignItems;
  if (showBottomAdd && index === 0) {
    alignItems = 'center';
  } else if (showBottomAdd) {
    alignItems = 'center';
  } else {
    alignItems = 'flex-end';
  }
  return <>
    <FlexStyled id={id} alignItems='flex-start'>
            {
        isSecondLevel ? <FlexStyled alignItems="center" style={{height:36}}>
          <FlexStyled alignItems='center'><AcrossLine style={{ marginLeft: 5, marginRight: 3 }} /><CircleHollowStyled isActive={isFocus} style={{ marginRight: 14 }} /></FlexStyled>
        </FlexStyled> : <FlexStyled alignItems={'flex-start'} >
          <FlexStyled alignItems='center' style={{ height: 36 }}><CircleBoxStyled style={{ marginRight: 8 }}><CircleStyled isActive={isFocus} /></CircleBoxStyled></FlexStyled>
        </FlexStyled>
      }
      <SyllabusEditContainerStyled onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <FlexStyled style={{ position: 'relative' }}>
          <FlexStyled flexShrink={0} alignItems='flex-start' >
            <FlexStyled alignItems='center' justifyContent='center' style={{ height: 36 }}>
              <Pointer id='drag-button' style={{ marginRight: 4, visibility: (hover || isFocus) ? undefined : 'hidden' }}>
                <div style={{ pointerEvents: 'none' }}>{icon3}</div>
              </Pointer>
            </FlexStyled>
          </FlexStyled>
          <div style={{ position: 'relative', marginLeft: isSecondLevel ? undefined : undefined }}>
            {
              showAdd && index2 !== 0 && <Line index2={index2} onClick={onClickAdd} />
            }
            <input id={'input-' + id} value={props.value}
                   placeholder='输入标题内容'
                   maxLength={20}
                   onChange={(e) => onChange(e.target.value)}
                   onFocus={() => setFocus(true)}
                   onBlur={() => setFocus(false)} />
            {
              showBottomAdd && <Line onClick={onClickAddBottom} />
            }
          </div>
          <FlexStyled alignItems="flex-start" style={{ width: 45 }}>
            <FlexStyled alignItems='center' style={{ height: 36 }}>
              {
                (isFocus || hover) && showDelete && <Pointer onClick={onClickDelete} onMouseDown={(e) => e.preventDefault()} style={{ marginLeft: 5 }}>
                  {icon}
                </Pointer>
              }
            </FlexStyled>
          </FlexStyled>
        </FlexStyled>
      </SyllabusEditContainerStyled>
    </FlexStyled>
  </>;
});



