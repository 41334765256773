import React from "react";
import styled from "styled-components";

const BackStyled = styled.div`
  font-size: 6px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
`;

const BackBoxStyled = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const leftIcon = (
  <div
    style={{ position: "relative", width: 12, height: 12 }}
    dangerouslySetInnerHTML={{
      __html: `<svg width="12px" height="12px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="position:absolute;top: 0;left:0">
      <g id="插件" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.402389172">
          <g id="全部生成-选择大纲" transform="translate(-485.000000, -268.000000)" fill="#2C2C2C" fill-rule="nonzero">
              <g id="编组-19" transform="translate(465.000000, 250.000000)">
                  <g id="字体下拉备份-6" transform="translate(20.000000, 18.000000)">
                      <path d="M11.0607516,2.38289302 L5.75246688,8.41362446 C5.46012803,8.70629176 5.43355177,9.17107961 5.67640855,9.49852574 L11.0559386,15.6122255 C11.2195152,15.7764563 11.4316185,15.8625 11.6519676,15.8625 C11.8726252,15.8625 12.0847569,15.7762368 12.2429863,15.616596 L12.3238393,15.5227464 C12.5394461,15.2315762 12.5420902,14.8320065 12.3316294,14.5379852 L7.5195,9 L12.2479924,3.56100387 C12.5691059,3.23959497 12.5691059,2.70939507 12.2427171,2.38289302 C11.9162101,2.05570233 11.3872586,2.05570233 11.0607516,2.38289302 Z" id="-"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>`,
    }}
  />
);
const Back = (props) => {
  const handleBack = () => {
    // eslint-disable-next-line react/prop-types
    props.onBack && props.onBack();
  };
  return (
    <BackBoxStyled onClick={handleBack} alignItems="center" style={{ marginBottom: 20 }}>
      {leftIcon}
      <BackStyled style={{ marginLeft: 6 }}>返回</BackStyled>
    </BackBoxStyled>
  );
};

export default Back;
