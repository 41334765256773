import { Dropdown, Menu } from "antd";
import withComponent from "../hoc/withComponent";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import styled from "styled-components";
import Fuse from "fuse.js";
import useForceUpdate from "../hooks/useForceUpdate";
import PerfectScrollbar from "react-perfect-scrollbar";
import { searchList, searchListPromise } from "../const";
import React from "react";
const AutoCompleteStyled = styled.div`
  input {
    outline: none;
    border: none;
    width: 100%;
    padding: 0 11px;
    background: white;
  }
`;
const options = {
  includeScore: true,
  keys: ["label"],
  threshold: 0.2,
};

const SStyled = styled.div`
  background: white;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);

  & .ant-dropdown-menu {
    box-shadow: none;
  }
`;

export default withComponent(
  forwardRef((props, ref) => {
    const {
      defaultValue,
      zIndex,
      onEnter,
      onClickAutoCompleteItem: onClickAutoCompleteItem$1,
      visible,
      onSearch: onSearch$1,
      onChange: onChange$1,
      value = "",
      onKeyDown: onKeyDown$1,
      placeholder = "输入你要写的PPT主题内容",
      onFocus: onFocus$1,
      onBlur: onBlur$1,
      disable,
      maxLength = 20,
    } = props;
    const that = useRef({
      fuse: undefined,
      maxLength: 30,
      isFocus: false,
      result: [],
      timer: 0,
    }).current;
    const inputRef = useRef();
    const [, update] = useForceUpdate();
    const fetch = () => {
      searchListPromise.then(() => {
        that.fuse = new Fuse(searchList, options);
        update();
      });
    };
    useEffect(() => {
      fetch();
    }, []);

    const onFocus = () => {
      that.isFocus = true;
      update();
    };
    const onBlur = () => {
      that.isFocus = false;
      update();
    };
    const autoComplete = (value) => {
      clearTimeout(that.timer);
      that.timer = setTimeout(() => {
        let result = that.fuse.search(value);
        if (result.length > 50) {
          result = result.slice(0, 50);
        }
        that.result = result;
        update();
      }, 250);
    };
    const onCompositionStart = () => {
      that.compositionStart = true;
    };
    const onCompositionEnd = (e) => {
      that.compositionStart = false;
      autoComplete(e.target.value);
    };
    const onChange = (e) => {
      update();
      if (!that.compositionStart) {
        autoComplete(e.target.value);
      }
      onChange$1(e.target.value);
    };

    const onClickAutoCompleteItem = (e) => {
      inputRef.current.blur?.();
      onClickAutoCompleteItem$1?.(e);
    };
    const onMouseDownAutoCompleteItem = (e) => {
      e.preventDefault();
    };
    const onKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.target.blur();
        e.stopPropagation();
        e.preventDefault();
        onEnter?.(e.target.value);
      }
    };

    const onScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const blur = () => {
      inputRef.current.blur?.();
    };
    useImperativeHandle(ref, () => ({ blur }));

    const height = that.result?.length < 10 ? that.result?.length * 32 + 16 : 320;
    const menu = useMemo(
      () => (
        <SStyled style={{ position: "relative", top: 8, width: "100%", padding: 2, height }}>
          <PerfectScrollbar style={{ height: "100%" }} onScroll={onScroll}>
            <Menu>
              {that.result?.map((item, index) => {
                return (
                  <Menu.Item
                    key={index}
                    onMouseDown={onMouseDownAutoCompleteItem}
                    onClick={() => onClickAutoCompleteItem(item.item.label)}
                  >
                    {item.item.label}
                  </Menu.Item>
                );
              })}
            </Menu>
          </PerfectScrollbar>
        </SStyled>
      ),
      [that.result]
    );
    const dropdownVisible = that.isFocus && that.result?.length > 0;
    useEffect(() => {}, []);
    return (
      <AutoCompleteStyled style={{ width: "100%", position: "relative", overflow: "hidden" }}>
        <Dropdown getPopupContainer={null} zIndex={zIndex} visible={dropdownVisible} overlay={menu}>
          <input
            ref={inputRef}
            value={value}
            defaultValue={defaultValue}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={onCompositionEnd}
            onKeyDown={onKeyDown}
            disabled={disable}
            placeholder={placeholder}
            maxLength={maxLength}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Dropdown>
      </AutoCompleteStyled>
    );
  })
);
