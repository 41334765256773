import { Button, Modal, Space, Checkbox } from "antd";
import React, { useEffect, useRef } from "react";
import Pointer from "../../styled/Pointer";
import FlexStyled from "../../styled/FlexStyled";
import store from "store";
import { gzhQRCode, gzhState } from "../../service/services";
import md5 from "md5";
import useForceUpdate from "../../hooks/useForceUpdate";
import LoadingContainer from "../LoadingContainer";
import withComponent from "../../hoc/withComponent";
import Back from "../Back";

let src =
  "https://oss.jianzeppt.com/user/311793/65a6dbcf-8b3b-464b-9828-3df09d026c57.png?x-oss-process=image/quality,Q_50";
const bg = `https://oss.jianzeppt.com/user/311793/0627632e-c931-44cc-aaad-998fd98a5935.png?x-oss-process=image/resize,h_500,w_320`;

{
  const img = new Image();
  img.src = src;
}

try {
  const img = new Image();
  img.src = bg;
} catch (e) {}

export const LoginContent = (props) => {
  const { onCancel } = props;
  return (
    <FlexStyled
      justifyContent="center"
      style={{
        position: "relative",
        height: 500,
        background: "#FFFFFF",
        borderRadius: 6,
        pointerEvents: "auto",
      }}
    >
      <FlexStyled justifyContent="center" style={{ height: "100%", width: 460 }}>
        <QRContent {...props} />
      </FlexStyled>
    </FlexStyled>
  );
};
const LoginQR = (props) => {
  const that = useRef({
    ticket: undefined,
    loading: false,
    checkSeconds: Date.now(),
    uuid: md5(String(Date.now())),
    checkStatusTimer: 0,
  }).current;
  const [, update] = useForceUpdate();

  const getId = () => {
    let id = that.uuid;
    if (store.get("user") && !store.get("user").isWXLogin) {
      id = id + `-user-id-${store.get("user").id}=`;
    }
    return id;
  };

  const isTimeout = () => that.checkSeconds <= 0;

  const fetchQR = () => {
    let uuid = getId();
    return gzhQRCode({
      state: uuid,
    })
      .then((resp) => {
        that.ticket = resp.ticket;
        that.checkSeconds = (+resp.expireSeconds || 7200) / 2;
        that.isExpire = false;
        that.error = false;
      })
      .catch(() => {
        that.error = true;
      })
      .finally(() => {
        that.loading = false;
        update();
      });
  };
  const checkStatus = async () => {
    if (isTimeout()) {
      that.isExpire = true;
      update();
      return;
    }
    that.checkSeconds = that.checkSeconds - 1;
    let uuid = getId();
    return gzhState({
      state: uuid,
    }).then((user) => {
      if (user && user.id) {
        clearInterval(that.checkStatusTimer);
        user.token = user.accessToken;
        store.set("user", user);
        if (user.isWXLogin) {
          store.set("fakeUser", false);
        }
        if (props.reload) {
          window.location.reload();
        }

        props.onSuccess(user);
      }
    });
  };

  const init = async () => {
    that.loading = true;
    update();
    try {
      that.checkStatusTimer = setInterval(checkStatus, 1000);
    } catch (e) {}
    await fetchQR();
  };
  const onClickRefresh = () => {
    clearInterval(that.checkStatusTimer);
    that.isExpire = false;
    that.error = false;
    init();
  };
  useEffect(() => {
    init();
    return () => {
      clearInterval(that.checkStatusTimer);
    };
  }, []);
  return (
    <LoadingContainer loading={that.loading} style={{ width: "100%", height: "100%", position: "relative" }}>
      {that.isExpire || that.error ? (
        <FlexStyled
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%", width: "100%" }}
        >
          <Space direction="vertical">
            <FlexStyled justifyContent="center">{that.error ? "未知错误" : "二维码已过期，请刷新网页。"}</FlexStyled>
            <Button type="primary" onClick={onClickRefresh}>
              点击重试
            </Button>
          </Space>
        </FlexStyled>
      ) : (
        <img
          key={1}
          alt="二维码"
          style={{ height: "100%", width: "100%" }}
          src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${that.ticket}`}
        />
      )}
    </LoadingContainer>
  );
};
const QRContent = (props) => {
  const fetchUser = async () => {
    props.onCancel?.();
    props.onSucessd?.();
  };
  return (
    <div style={{ width: 250, paddingTop: 44 }}>
      <FlexStyled alignItems="center" justifyContent="space-between">
        <div
          style={{
            height: 24,
            width: 24,
            backgroundImage:
              "url(https://oss.jianzeppt.com/user/28280/850e09e6-20b8-4ba4-8ac9-7ccf0daec2d2.png?x-oss-process=image/quality,Q_50)",
            backgroundSize: "100% 100%",
          }}
        />
        <div
          style={{
            fontSize: 24,
            fontFamily: "PingFangSC-Medium, PingFang SC",
            color: "#222222",
            lineHeight: "24px",
          }}
        >
          微信扫码登录或注册
        </div>
      </FlexStyled>

      <FlexStyled alignItems="center" justifyContent="space-between" style={{ marginTop: 18 }}>
        <div
          style={{
            height: 2,
            width: 60,
            backgroundImage: "url(https://oss.jianzeppt.com/user/28280/05fc0efa-10d7-4c4a-9447-c31a84da5f96.png)",
            backgroundSize: "100% 100%",
          }}
        />
        <div
          style={{
            fontSize: 14,
            fontFamily: "PingFangSC-Medium, PingFang SC",
            color: "#7B7B7B",
            fontWeight: 400,
            lineHeight: "14px",
          }}
        >
          扫码登录 快速下载
        </div>
        <div
          style={{
            height: 2,
            width: 60,
            backgroundImage: "url(https://oss.jianzeppt.com/user/28280/b1dd7ef3-ad62-424e-9915-d3026db33230.png)",
            backgroundSize: "100% 100%",
          }}
        />
      </FlexStyled>
      <FlexStyled
        alignItems="center"
        justifyContent="center"
        style={{
          width: 250,
          height: 250,
          border: "1px solid #E6E6E6",
          marginTop: 20,
        }}
      >
        <div style={{ height: 218, width: 218 }}>
          <LoginQR onSuccess={fetchUser} reload={props.reload} />
        </div>
      </FlexStyled>
      <FlexStyled flexDirection="column" justifyContent="center" style={{ marginTop: 18 }}>
        <FlexStyled justifyContent="center" style={{ fontSize: 14, whiteSpace: "nowrap" }}>
          扫码关注 “秒出PPT” 即可登录注册
        </FlexStyled>
        <FlexStyled justifyContent="center" style={{ fontSize: 14 }}>
          首次扫码将自动注册新账号
        </FlexStyled>
      </FlexStyled>
      <FlexStyled justifyContent="center" alignItems="center" style={{ marginTop: 28 }}>
        <Checkbox defaultChecked={true} style={{ position: "relative", top: 1 }} />
        <FlexStyled style={{ marginLeft: 6 }}>
          <a target="_blank" style={{ color: "#BBBBBB", fontSize: 14, fontWeight: 400 }}>
            我已阅读并接受《用户协议》
          </a>
        </FlexStyled>
      </FlexStyled>
    </div>
  );
};

export default withComponent((props) => {
  const { getContainer = false, visible, mask = true } = props;
  // return (
  //   <Modal
  //     visible={visible}
  //     getContainer={getContainer}
  //     style={{ margin: "auto" }}
  //     destroyOnClose
  //     centered
  //     mask={mask}
  //     width={780}
  //     modalRender={() => {
  //       return <LoginContent {...props} />;
  //     }}
  //   />
  // );

  return (
    visible && (
      <div>
        <Back onBack={() => props.onBack && props.onBack()} />
        <LoginContent {...props} />;
      </div>
    )
  );
});
