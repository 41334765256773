import withComponent from '../../../hoc/withComponent';
import styled from 'styled-components';
import FlexStyled from '../../../styled/FlexStyled';
import React from 'react';

const BoxStyled = styled.div`
  cursor: pointer;
  width: 290px;
  height: fit-content;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #DEDEDE;


  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2C2C2C;
  line-height: 24px;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  :hover {
    border: 1px solid #11BBA6;
    box-shadow: 0px 3px 15px 0px rgba(0, 188, 166, 0.19);
  }
`;
const TitleText = styled.div`
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CircleBoxStyled = styled.div`
  position: relative;
  flex-shrink: 0;
  z-index: 2;
  left: -3px;
  background: white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CircleStyled = styled.div`
  width: 8px;
  height: 8px;
  background: #D8D8D8;
  border-radius: 50%;
  overflow: hidden;
`;
const CircleHollowStyled = styled.div`
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #D8D8D8;
  border-radius: 50%;
  overflow: hidden;
`;
const AcrossLine = styled.div`
  width: 20px;
  height: 1px;
  background: #EEEEEE;
  flex-shrink: 0;
`;
const VerticalLine = styled.div`
  position: absolute;
  top: 28px;
  bottom: 28px;
  left: 23px;
  width: 1px;
  background: #EEEEEE;
`;

export default withComponent((props) => {
  const { syllabus, onClick, isActive } = props;

  return <BoxStyled onClick={onClick}
                    isActive={isActive}
                    style={{ padding: '16px 20px' }}>
    {syllabus.map((item, index) => {
      return <React.Fragment key={item.id}>
        <FlexStyled alignItems='center' style={{ marginTop: 8 }}><CircleBoxStyled style={{ marginRight: 8 }}><CircleStyled /></CircleBoxStyled><TitleText>{item.title}</TitleText></FlexStyled>
        {
          item.children?.map((i, index2) => {
            return <FlexStyled alignItems='center' style={{ marginTop: 8 }}><AcrossLine style={{ marginLeft: 5, marginRight: 3 }} /><CircleHollowStyled style={{ marginRight: 14 }} /><TitleText>{i.title}</TitleText></FlexStyled>;
          })
        }
      </React.Fragment>;
    })}
    <VerticalLine />
  </BoxStyled>;
});
