const getNodeMarkKeyById = (nodeMark, id) => {
  let keys = [];
  let flag = true;
  const pushKey = (key) => {
    if (flag) {
      keys.push(key);
    }
  };
  const popKey = () => {
    if (flag) {
      keys.pop();
    }
  };
  const get = (value) => {
    if (value instanceof Array) {
      value.forEach((value, index) => {
        pushKey(`[${index}]`);
        get(value);
        popKey();
      });
    } else if (typeof value === 'string' && value === id) {
      flag = false;
    } else if (value instanceof Object) {
      Object.keys(value).forEach((key) => {
        const target = value[key];
        if(keys.length===0){
          pushKey( key);
        }else{
          pushKey('.' + key);
        }
        get(target);
        popKey();
      });
    }
  };
  get(nodeMark);
  return keys.join('');
};
export default getNodeMarkKeyById;
