import getNodes from './getNodes';
import isThemeNodeById from './isThemeNodeById.';
import getNodeMarkKeyById from './getNodeMarkKeyById';
import createNodeId from './createNodeId';
import { INodeType } from './INode';
import { getNodeTypeNodeMark } from './const';
import replaceHTML from './replaceHTML';
import lodash from "lodash";
import { v4 } from "uuid";

export const changeSVGImage = (oldV, value) => {
  const dom = new DOMParser().parseFromString(oldV, 'text/html');
  const svg = dom.body.querySelector('svg');
  const path = dom.body.querySelector('path');
  const image = dom.body.querySelector('image');
  if (path) {
    let fill = path?.getAttribute('fill');
    fill = fill.replace('url(#', '');
    fill = fill.replace(')', '');
    const defs = dom.body.querySelector('defs');
    if (defs) {
      const patterns = defs.querySelectorAll('pattern');
      patterns.forEach((pattern) => {
        if (pattern.getAttribute('id') === fill) {
          const image = pattern.querySelector('image');
          if (image) {
            const id = v4();
            path.setAttribute('fill', `url(#${id})`);
            pattern.setAttribute('id', id);
            image.setAttribute('xlink:href', value);
          }
        }
      });
    }
  } else {
    image.setAttribute('xlink:href', value);
  }
  return svg.outerHTML;
};
export const isSVGImage = (value) => {
  const dom = new DOMParser().parseFromString(value, 'text/html');
  const image = dom.body.querySelector('image');
  return !!image;
};
export const getSVGImage = (value) => {
  const dom = new DOMParser().parseFromString(value, 'text/html');
  const image = dom.body.querySelector('image');
  return image.getAttribute('xlink:href');
};


export const catalogueBoxType = {
  title: 'title',
  list: 'list',
  other: 'other',
};

export const coverBoxType = {
  title: 'title',
  des: 'des',
  author: 'author',
  date: 'date',
  other: 'other',
};
export const sectionBoxType = {
  title: 'title',
  partNum: 'partNum',
  other: 'other',
};
export const lastBoxType = {
  title: 'title',
  describe: 'describe',
  other: 'other',
};
export const contentBoxType = {
  title: 'title',
  main: 'main',
  list: 'list',
  plate: 'plate',
  chart: 'chart',
  other: 'other',
};


export default (params) => {
  const { currentTheme, currentPages } = params;
  const { targetTheme } = params;
  const { replaceTemplate } = params;
  let pages = {};
  Object.keys(currentPages).forEach((currentPageId) => {
    if (replaceTemplate[currentPageId]) {
      const { replaceMap } = replaceTemplate[currentPageId];
      if (!replaceMap) {
        pages[currentPageId] = currentPages[currentPageId];
      }
      const currentPage = currentPages[currentPageId];
      const targetPage = JSON.parse(replaceTemplate[currentPageId].page);
      const currentNodes = getNodes({ page: currentPage, theme: currentTheme });
      const targetNodes = getNodes({ page: targetPage, theme: targetTheme });

      Object.keys(replaceMap).map((currentNodeId) => {
        const targetNodeId = replaceMap[currentNodeId];
        const currentNode = currentNodes[currentNodeId];
        const currentNodeValue = currentNode.value;
        let targetNode = JSON.parse(JSON.stringify(targetNodes[targetNodeId]));
        const targetNodeValue = targetNode.value;
        const targetIsThemeNodeId = isThemeNodeById(targetNodeId);
        if (targetIsThemeNodeId) {
          const path = getNodeMarkKeyById(targetPage.nodeMark, targetNodeId);
          const id = createNodeId();
          lodash.set(targetPage.nodeMark, path, id);
          targetNode.id = id;
          if (targetPage.excludedThemeNodes) {
            targetPage.excludedThemeNodes.push(targetNodeId);
          } else {
            targetPage.excludedThemeNodes = [targetNodeId];
          }
        }
        switch (targetNode?.type) {
          case INodeType.text: {
            const dom = new DOMParser().parseFromString(currentNodeValue, 'text/html');
            targetNode.value = replaceHTML(dom.body.innerText, targetNodeValue);
            break;
          }
          case INodeType.svg: {
            switch (currentNode?.type) {
              case INodeType.svg: {
                if (isSVGImage(currentNode.value) && isSVGImage(targetNode.value)) {
                  targetNode.value = changeSVGImage(targetNode.value, currentNode.value);
                } else {
                  targetNode.value = currentNode.value;
                }
                break;
              }
              //如果是图表或图片替换定位
              case INodeType.charts:
              case INodeType.image: {
                targetNode.value = changeSVGImage(targetNode.value, currentNode.value);
                break;
              }
            }
            break;
          }
          case INodeType.charts: {
            switch (currentNode?.type) {
              case INodeType.charts: {
                targetNode.value = currentNodeValue;
                break;
              }
              case INodeType.svg:

              case INodeType.image: {

                const node = JSON.parse(JSON.stringify(currentNode));
                node.style.top = targetNode?.style?.top;
                node.style.left = targetNode?.style?.left;
                node.style.width = targetNode?.style?.width;
                node.style.height = targetNode?.style?.height;
                node.style.zIndex = targetNode?.style?.zIndex;
                targetNode = node;
                break;
              }
            }
            break;
          }
          case INodeType.image: {
            switch (currentNode?.type) {
              case INodeType.image: {
                targetNode.value = currentNodeValue;
                break;
              }
              case INodeType.svg:
              case INodeType.charts: {
                if (isSVGImage(currentNode.value)) {
                  targetNode.value = getSVGImage(currentNode.value);
                } else {
                  const node = JSON.parse(JSON.stringify(currentNode));
                  node.style.top = targetNode?.style?.top;
                  node.style.left = targetNode?.style?.left;
                  node.style.width = targetNode?.style?.width;
                  node.style.height = targetNode?.style?.height;
                  node.style.zIndex = targetNode?.style?.zIndex;
                  targetNode = node;
                }

                break;
              }
            }
            break;
          }
        }

        targetPage.nodes[targetNode.id] = targetNode;
      });
      {
        const targetPageNodes = getNodes({ page: targetPage, theme: targetTheme });
        Object.keys(targetPageNodes).forEach((id) => {
          const node = targetPageNodes[id];
          const type = getNodeTypeNodeMark(targetPage, node);
          const handler = () => {
            if (node.type === INodeType.text) {
              node.wordWrap = false;
            }
          };
          switch (targetPage.type) {
            case 'cover': {
              if (coverBoxType.title === type) {
                handler();
              }
              break;
            }
            case 'transition': {
              if (sectionBoxType.title === type) {
                handler();

              }
              break;
            }
            case 'end': {
              if (lastBoxType.title === type) {
                handler();

              }
              break;
            }
            case 'main': {
              if (contentBoxType.title === type) {
                handler();

              }
              break;
            }

          }
        });
      }

      targetPage.id = currentPage.id;
      pages[currentPageId] = targetPage;
    } else {
      pages[currentPageId] = currentPages[currentPageId];
    }
  });
  return pages;
}
